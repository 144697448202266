import { post } from '@rails/request.js';
import FormController from './../components/form_controller';

export default class extends FormController {
  initialFormData = {};

  static targets = [
    'imageCard',
    'imageErrorMsg',
    'leadTimeInput',
    'updateItemTypeButton',
    'itemTypeDropdown',
    'optionSetDropdown',
    'optionSection',
    'categoryDropdown',
    'additionalOptionsEmpty',
    'createCustomCategory',
  ];

  connect() {
    // give all elements a moment to populate before checking initial values
    setTimeout(() => {
      this.initialFormData = this.getFormValues();
      this.validate(null, false);
    }, 100);

    this.productAlertElement = document.querySelector('#product-alert') || null;
  }

  errorClass = 'error';
  hiddenClass = 'hidden';
  alertErrorClass = 'alert-red';
  alertGrayClass = 'alert-gray';

  toggleLeadTime(e) {
    this.leadTimeInputTarget.classList.toggle(this.hiddenClass);
    const dropdowns = this.dropdownTargets.filter((ele) => {
      return ele.id.includes('lead_time');
    });
    dropdowns.forEach((dd) => {
      const controller = this.getControllerByElement(dd);
      if (controller) controller.inputTarget.required = !e.target.checked;
    });
  }

  // Updates the products format and renders the option tables
  updateItemType() {
    const itemTypeDropdownController = this.getControllerByElement(this.itemTypeDropdownTarget);
    const itemTypeValue = itemTypeDropdownController?.inputTarget.value;
    const url = this.updateItemTypeButtonTarget.dataset?.url;

    if (!itemTypeDropdownController.validate()) return;

    post(url, {
      body: {
        // eslint-disable-next-line camelcase
        item_type: itemTypeValue,
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    }).then((resp) => {
      if (resp.ok) {
        this.optionSectionTarget.classList.remove(this.hiddenClass);
        this.updateItemTypeButtonTarget.setAttribute('disabled', true);
        itemTypeDropdownController.disable();
      }
    });
  }

  dropdownUpdate() {
    this.isFormChanged();
  }

  updateOptions() {
    if (!this.hasOptionSetDropdownTarget) return;
    const optionSetDropdownController = this.getControllerByElement(this.optionSetDropdownTarget);
    const optionValues = optionSetDropdownController.inputTarget.value;
    const url = this.optionSetDropdownTarget.dataset.url;

    if (!optionSetDropdownController.validate()) return;

    post(url, {
      body: {
        // eslint-disable-next-line camelcase
        option_sets: optionValues,
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    }).then((res) => {
      if (res.ok) {
        if (this.hasAdditionalOptionsEmptyTarget) {
          if (optionValues.length) {
            this.additionalOptionsEmptyTarget.classList.add(this.hiddenClass);
          } else {
            this.additionalOptionsEmptyTarget.classList.remove(this.hiddenClass);
          }
        }
      }
    });
  }

  validate(e, showError = true) {
    let valid = true;
    const itemTypeDropdownController = this.getControllerByElement(this.itemTypeDropdownTarget);

    // Only exists on custom product page
    let categoryDropdownController;
    if (this.hasCategoryDropdownTarget) {
      categoryDropdownController = this.getControllerByElement(this.categoryDropdownTarget);
    }

    // validate that there is an image uploaded for the first image
    // if (this.imageCardTargets.length
    //   && this.imageCardTargets[0]
    //   && !this.imageCardTargets[0].dataset?.url
    // ) {
    //   valid = false;
    //   if (showError) {
    //     // add error message and highlight on card
    //     this.imageCardTargets[0].classList.add(this.errorClass);
    //     this.imageErrorMsgTargets[0].classList.remove(this.hiddenClass);
    //   }
    // }

    if (!super.validate(e, showError)) {
      // Have to call manually because it is not a standard dropdown target
      itemTypeDropdownController.validate(showError);
      if (categoryDropdownController) categoryDropdownController.validate(showError);
      valid = false;
    }

    if (!itemTypeDropdownController.validate(showError)
      || (categoryDropdownController && !categoryDropdownController.validate(showError))) {
      valid = false;
    }

    if (!itemTypeDropdownController.disabledValue) {
      if (showError) {
        itemTypeDropdownController.setCustomError('Please confirm your format before saving.');
      }
      valid = false;
    } else {
      itemTypeDropdownController.clearCustomError();
    }

    if (!valid) {
      if (this.productAlertElement) {
        if (showError) {
          this.productAlertElement.classList.add(this.alertErrorClass);
          this.productAlertElement.classList.remove(this.alertGrayClass);
        } else {
          this.productAlertElement.classList.remove(this.hiddenClass);
        }
      }
      if (showError) window.scrollTo({ top: 0, behavior: 'smooth' });
      if (e) e.preventDefault();
    }
  }

  removeImageError() {
    // remove error message and highlight on card
    this.imageCardTargets[0].classList.remove(this.errorClass);
    this.imageErrorMsgTargets[0].classList.add(this.hiddenClass);
  }

  getFormValues() {
    if (!this.hasFormTarget) return [];

    const elements = [];

    const inputs = this.formTarget?.querySelectorAll('input', 'select');
    inputs.forEach((input) => {
      elements.push(input.value);
    });

    const textareas = this.formTarget?.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      elements.push(textarea.value);
    });

    const toggles = this.formTarget?.querySelectorAll('input[type=checkbox]');
    toggles.forEach((toggle) => {
      elements.push(toggle.checked);
    });

    return elements;
  }

  isFormChanged() {
    const currentValues = this.getFormValues();
    const isDirty = JSON.stringify(currentValues) !== JSON.stringify(this.initialFormData);
    return isDirty;
  }

  formSaveComplete() {
    this.initialFormData = this.getFormValues();
  }

  handleModal() {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    modal.setAttribute('data-endpoint', this.getUrl());
    modal.click();
  }

  leavingPage(e) {
    if (this.isFormChanged()) {
      e.preventDefault();
      this.setUrl(e.detail.url);
      this.handleModal();
    }
  }

  setUrl(url) {
    this.url = url;
  }

  getUrl() {
    return this.url;
  }

  handleCreateCategory() {
    const dropdownTarget = this.getControllerByElement(this.categoryDropdownTarget);
    const url = new URL(this.createCustomCategoryTarget.dataset.baseUrl, window.location.origin);
    url.searchParams.append('tmp_categories', dropdownTarget.inputTarget.value);
    this.createCustomCategoryTarget.setAttribute('href', url.href);
  }
}
