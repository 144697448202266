// This file is responsible for importing all controllers into phx.js to be used
import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

const turboDebug = false;

/* eslint-disable no-console */
if (turboDebug) {
  console.log('entire document refreshed');

  document.addEventListener('turbo:visit', (e) => {
    return console.log('turbo visit action:', e.detail.action);
  });

  document.addEventListener('turbo:morph', () => {
    return console.log('turbo morph render');
  });

  document.addEventListener('turbo:frame-render', (e) => {
    return console.log('turbo frame render:', e.target.id);
  });

  document.addEventListener('turbo:before-stream-render', (e) => {
    return console.log(`turbo stream render (${e.target.action} event):`, e.target);
  });

  document.addEventListener('turbo:submit-start', (e) => {
    return console.log('turbo submit start:', e.target);
  });

  document.addEventListener('turbo:submit-end', (e) => {
    return console.log('turbo submit end:', e.target);
  });
}
/* eslint-enable no-console */

/** Global Controller */
import GlobalController from './global_controller';
application.register('global', GlobalController);

/** Component Controllers */
import ButtonController from './components/button_controller';
application.register('button', ButtonController);

import CardController from './components/card_controller';
application.register('card', CardController);

import Dropdown from './components/dropdown_controller';
application.register('basic-dropdown', Dropdown);

import FormController from './components/form_controller';
application.register('form', FormController);

import ModalController from './modal_controller';
application.register('modal', ModalController);

import PaginationController from './components/pagination_controller';
application.register('pagination', PaginationController);

import SidebarController from './sidebar_controller';
application.register('sidebar', SidebarController);

import Tabs from './components/tabs_controller';
application.register('tabs', Tabs);

import TabulatorGroupingController from './components/tabulator_grouping_controller';
application.register('tabulator-grouping', TabulatorGroupingController);

import TextInputController from './components/text_input_controller';
application.register('text-input', TextInputController);

import TinyMCE from './tinymce_controller';
application.register('tinymce', TinyMCE);

import ToastController from './toast_controller';
application.register('toast', ToastController);

import ToggleController from './components/toggle_controller';
application.register('toggle', ToggleController);

import ToggleDropdownController from './components/toggle_dropdown_controller';
application.register('toggle-dropdown', ToggleDropdownController);

import ToggleInputController from './components/toggle_input_controller';
application.register('toggle-input', ToggleInputController);

import TooltipController from './components/tooltip_controller';
application.register('tooltip', TooltipController);

import CarouselController from './components/carousel_controller';
application.register('carousel', CarouselController);

import UnsavedModalController from './unsaved_modal_controller';
application.register('unsaved-modal', UnsavedModalController);

/** Catalog Controllers */

import CatalogNewProductController from './catalog/catalog_new_product_controller';
application.register('catalog-new-product', CatalogNewProductController);

import CatalogSettingsController from './catalog/catalog_settings_controller';
application.register('catalog-settings', CatalogSettingsController);

import ChangelogController from './changelog_controller';
application.register('changelog', ChangelogController);

import DesignFilterController from './catalog/design_filter_controller';
application.register('design-filter', DesignFilterController);

import AddOnFilterController from './catalog/addon_filter_controller';
application.register('addon-filter', AddOnFilterController);

import DesignAddonFormController from './catalog/design_addon_form_controller';
application.register('design-addon-form', DesignAddonFormController);

import ProductImageController from './components/product_image_controller';
application.register('product-image', ProductImageController);

import CatalogProductController from './catalog/catalog_product_controller';
application.register('catalog-product', CatalogProductController);
import CatalogProductDetailController from './catalog/catalog_product_details_controller';
application.register('catalog-product-details', CatalogProductDetailController);

// Catalog Parameters
import CatalogParameterAssignProductController from './catalog/parameters/parameter_assign_product_controller';
application.register('parameter-assign-product', CatalogParameterAssignProductController);

import CatalogParameterListController from './catalog/parameters/parameter_list_controller';
application.register('parameter-list', CatalogParameterListController);

import CatalogParameterFormController from './catalog/parameters/parameter_form_controller';
application.register('parameter-form', CatalogParameterFormController);

import ParameterOptionController from './catalog/parameters/parameter_option_controller';
application.register('parameter-option', ParameterOptionController);

import CatalogOptionSetTableController from './catalog/option_set_table_controller';
application.register('option-set-table', CatalogOptionSetTableController);

// catalog products index page
import CatalogProductGridController from './catalog/catalog_product_grid_controller';
application.register('catalog-product-grid', CatalogProductGridController);

import ProductCardController from './components/product_card_controller';
application.register('product-card', ProductCardController);

/** Location Controllers */
import LocationFormController from './location_form_controller';
application.register('location-form', LocationFormController);

import LocationsController from './locations_controller';
application.register('locations', LocationsController);

import ReportPageController from './report_page_controller';
application.register('report-page', ReportPageController);

import LocationsGroupController from './locations_group_controller';
application.register('locations-group', LocationsGroupController);

/** Order Queue Controllers */
import OrderQueueController from './order_queue_controller';
application.register('order-queue', OrderQueueController);

import OrderDetailsController from './order_details_controller';
application.register('order-details', OrderDetailsController);

/** Storefront Controllers */
import BannerController from './banner_controller';
application.register('banner', BannerController);

import CustomCategoryFormController from './custom_category_form_controller';
application.register('custom-category-form', CustomCategoryFormController);

import FeaturedCategoryFormController from './featured_category_form_controller';
application.register('featured-category-form', FeaturedCategoryFormController);

import StorefrontsController from './storefronts_controller';
application.register('storefronts', StorefrontsController);

import StorefrontBrandsController from './storefront_brands_controller';
application.register('storefront-brands', StorefrontBrandsController);

import StorefrontGroupsController from './storefront_groups_controller';
application.register('storefront-groups', StorefrontGroupsController);

import StorefrontLocationsController from './storefront_locations_controller';
application.register('storefront-locations', StorefrontLocationsController);

/** Store Hours Controllers */
import HolidayHoursController from './holiday_hours_controller';
application.register('holiday-hours', HolidayHoursController);

import MassUpdateHolidayHoursController from './mass_update_holiday_hours_controller';
application.register('mass-update-holiday-hours', MassUpdateHolidayHoursController);

import MassUpdateStoreHoursController from './mass_update_store_hours_controller';
application.register('mass-update-store-hours', MassUpdateStoreHoursController);

import StoreHoursController from './store_hours_controller';
application.register('store-hours', StoreHoursController);

/** User Controllers */
import UsersFiltersController from './users_filters_controller';
application.register('users-filters', UsersFiltersController);

import UsersCreateEditController from './users_create_edit_controller';
application.register('users-create-edit', UsersCreateEditController);

import NotificationFiltersController from './notification_filters_controller';
application.register('notification-filters', NotificationFiltersController);

/** MFA Controllers */
import MfaCreateController from './mfa_create_controller';
application.register('mfa-create', MfaCreateController);

import MfaValidateVerifyController from './mfa_validate_verify_controller';
application.register('mfa-validate-verify', MfaValidateVerifyController);
