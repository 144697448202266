import FormController from './components/form_controller';

export default class extends FormController {
  initialFormData = {};
  isDirty = false;
  locationsSelected = false;

  static targets = [
    'saveButton',
    'tbody',
    'toggle247',
    'toggle',
    'locationsDropdown',
    'groupsDropdown',
  ];

  // fires when the controller is attached
  connect() {
    // give all elements a moment to populate before checking initial values
    setTimeout(() => {
      this.initialFormData = this.getFormData();
    }, 100);
  }

  // returns an object with all form values
  getFormData() {
    const formData = {
      rows: [],
    };

    const is247 = this.toggle247Target?.checked; // the 24/7 toggle
    const tbody = this.tbodyTarget;
    const rows = tbody.querySelectorAll('tr');
    rows.forEach((row) => {
      const isOpen = row.querySelector('input[type=checkbox]')?.checked || false;
      const dropdowns = row.querySelectorAll('.dropdown');
      const openTime = dropdowns[0]?.querySelector('input[type=hidden]')?.value || '';
      const closeTime = dropdowns[1]?.querySelector('input[type=hidden]')?.value || '';

      formData.rows.push({
        isOpen,
        closeTime,
        openTime,
      });
    });

    formData.is247 = is247;

    return formData;
  }

  // checks if the form values have changed from the initial state
  checkFormIsDirty() {
    const formData = this.getFormData();
    this.isDirty = JSON.stringify(formData) !== JSON.stringify(this.initialFormData);
  }

  // enables/disables a row's open/close time dropdowns
  // based on the state of the open/close toggle switch
  toggleOpen(e) {
    const row = e?.target?.closest('tr'); // the current row
    const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
    const toggleWrapper = row?.querySelector('.input-toggle'); // the wrapper for the toggle
    const toggle = toggleWrapper?.querySelector('input[type=checkbox]'); // the toggle input itself
    const checked = toggle?.checked; // whether it's checked or not

    // loop through the dropdowns and enable or disable them
    dropdowns?.forEach((d) => {
      const controller = this.getControllerByElement(d);
      if (checked) {
        controller.enable();
      } else {
        controller.disable();
      }
    });
  }

  // fires when stores are selected or deselected
  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;

    // find and reduce the close time dropdown's options based on selected open time
    if (dropdown.id.includes('open-time')) {
      const row = e?.target?.closest('tr'); // the current row
      const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
      const openValue = dropdown.getSelection()?.value;
      const closeParent = dropdowns[1]; // the closeTime dropdown
      const closeList = closeParent?.querySelector('.dropdown-list')?.childNodes[1]?.childNodes;
      closeList.forEach((r) => {
        const rowValue = r.dataset?.dropdownValue;
        if (rowValue) {
          if (this.strTimeForSort(openValue) >= this.strTimeForSort(rowValue)) {
            r.classList.add('hidden');
          } else {
            r.classList?.remove('hidden');
          }
        }
      });
    }

    this.checkSaveButtonState();
  }

  // converts a string time "10:00 AM" to a number for sorting
  strTimeForSort(time) {
    const timeParts = time.split(' ');
    const timeStr = timeParts[0];
    const ampm = timeParts[1]?.toLowerCase();
    const hourMin = timeStr?.split(':');
    let hour = parseInt(hourMin[0], 10);
    if (hour === 12) hour = 0;
    if (ampm === 'pm') hour += 12;
    const min = parseInt(hourMin[1], 10);
    return hour + (min === 30 ? 0.5 : 0);
  }

  // sets the save button enabled or disabled based on form state
  checkSaveButtonState() {
    // check if stores have been selected
    const selectedLocations = this.locationsDropdownTarget.querySelector('input').value;
    const selectedGroups = this.groupsDropdownTarget.querySelector('input').value;
    this.locationsSelected = !!(selectedLocations || selectedGroups);

    // check if time selections have been made
    let formComplete = false;
    const toggle247Checked = this.toggle247Target?.checked;
    if (toggle247Checked) {
      formComplete = true;
    } else {
      // check all time dropdowns for values
      const tbody = this.tbodyTarget;
      const rows = tbody.querySelectorAll('tr');
      const rowChecks = [];

      rows.forEach((row) => {
        const isOpen = !!row.querySelector('input[type=checkbox').checked;
        const rowInputs = row.querySelectorAll('input[type=hidden]');
        const hasOpen = !!rowInputs[1].value; // open time dropdown
        const hasClose = !!rowInputs[2].value; // close time dropdown
        const hasHours = isOpen ? (hasOpen && hasClose) : true;
        rowChecks.push(hasHours);
      });

      formComplete = !rowChecks.some((r) => { return r === false; });
    }

    // check if the form is dirty
    this.checkFormIsDirty();

    // enable or disable the save button
    this.saveButtonTarget.disabled = !this.locationsSelected || !formComplete;
  }

  // handle leaving the page and triggering the unsaved changes modal
  leavingPage(e) {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    const controller = this.getControllerByElement(modal);

    // check if there are unsaved changes
    if (this.isDirty) {
      e.preventDefault();
      controller.setUrl(e.detail.url);
      controller.handleModal();
    }
  }

  // set isDirty false on save so we don't get the
  // unsaved changes modal when the save action redirects
  saveForm() {
    this.isDirty = false;
  }
}
