/* eslint-disable no-multiple-empty-lines */
import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class FormController extends Controller {
  filterTimeoutId = null;
  searchFilterTimeout = 750;
  loadingAttribute = 'aria-busy';

  static targets = ['form', 'input', 'dropdown', 'filterSection'];

  static values = {
    turboFrame: String,
  };

  validate(e, showError = true) {
    let invalid = false;

    // Find all input controllers and run their validation method
    this.inputTargets.forEach((i) => {
      const inputController = this.getControllerByElement(i, 'text-input');
      if (!inputController) return;
      if (!inputController.validate(showError)) invalid = true;
    });

    // Find all dropdown controllers and run their validation method
    this.dropdownTargets.forEach((i) => {
      // eslint-disable-next-line max-len
      const dropdownController = this.getControllerByElement(i, i.dataset.controller);
      if (!dropdownController) return;
      if (!dropdownController.validate(showError)) invalid = true;
    });

    if (e && invalid) e.preventDefault();

    return !invalid;
  }

  // Overridable method for custom dropdown input changes
  // Must define empty method here to prevent errors
  // eslint-disable-next-line no-unused-vars, no-empty-function
  dropdownUpdate(e) { }

  // Overridable method for closing dropdowns
  // Must define dropdownIds in controller
  // ex: dropdownIds = ['departments', 'types', 'status'];
  dropdownBlur(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.initializing) return;

    // filter results
    if (this.dropdownIds && this.dropdownIds.includes(dropdown.id) && e.detail.wasChanged) {
      this.sendFilterRequest();
    }
  }

  // Overridable method for dropdowns in a ready state
  // Must define empty method here to prevent errors
  // eslint-disable-next-line no-unused-vars, no-empty-function
  dropdownReady(e) { }

  getControllerByElement(element) {
    if (!element) return null;
    // eslint-disable-next-line max-len
    return this.application.getControllerForElementAndIdentifier(element, element.dataset.controller);
  }

  getDropdownControllerById(id) {
    if (!this.hasDropdownTarget) return null;
    const element = this.dropdownTargets.find((t) => {
      return t.id === id;
    });
    if (!element) return null;
    // eslint-disable-next-line max-len
    return this.application.getControllerForElementAndIdentifier(element, element.dataset.controller);
  }

  search() {
    // delay search
    if (this.filterTimeoutId) clearTimeout(this.filterTimeoutId);
    this.filterTimeoutId = setTimeout(() => {
      this.sendFilterRequest();
    }, this.searchFilterTimeout);
  }

  getFormDataString() {
    // get form values as '&' delimited string
    const formData = new FormData(this.formTarget);
    const formDataObj = Object.fromEntries(formData.entries());
    const formDataArr = Object.keys(formDataObj).map((key) => {
      return `${key}=${encodeURIComponent(formDataObj[key])}`;
    });
    const formDataStr = formDataArr.join('&');
    return formDataStr;
  }

  // Overridable method for submitting a form
  // Must define form in controller as target
  // ex: static targets = ['form']
  async sendFilterRequest() {
    let turboFrame;

    // get ref to turbo frame so we can show a loading indicator
    if (this.hasTurboFrameValue) {
      turboFrame = document.querySelector(`#${this.turboFrameValue}`);
    }

    // add attribute to display the loading indicator
    if (turboFrame) {
      turboFrame.setAttribute(this.loadingAttribute, true);
    }

    // get form data and send a get request
    const formDataStr = this.getFormDataString();
    const url = encodeURI(`${this.formTarget.action}?${formDataStr}`);
    history.pushState('', '', url);
    await get(url, {
      responseKind: 'turbo-stream',
    });

    // remove this attribute to hide the loading indicator
    if (turboFrame) {
      turboFrame.removeAttribute(this.loadingAttribute);
    }
  }

  toggleAdditionalFilterSection() {
    if (this.hasFilterSectionTarget) {
      this.filterSectionTarget.classList.toggle('open');
    }
  }
}
