import Form from '../../components/form_controller';

export default class extends Form {
  dropdownIds = ['types', 'locations'];
  hiddenClass = 'hidden';

  static targets = [
    'form',
    'row',
    'table',
  ];

  open(e) {
    const row = e.currentTarget;
    const setId = row.dataset?.setId;
    // eslint-disable-next-line max-len
    this.tableTarget.querySelector(`tbody[data-set-id="${setId}"]`).classList.toggle(this.hiddenClass);
    const expanded = !(row.getAttribute('aria-expanded') === 'true');
    row.setAttribute('aria-expanded', expanded);
    row.classList.toggle('open');
  }
}
