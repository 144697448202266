import Form from './../components/form_controller';
// import { get } from '@rails/request.js';

export default class CatalogProductGridController extends Form {
  buttonSelectedClass = 'selected';
  dropdownIds = ['departments', 'product_types', 'decopac_types', 'status', 'per_page'];
  defaultGridType = 'large';

  static targets = [
    'form',
    'gridButton',
    'hiddenGridTypeEl',
  ];

  connect() {
    this.gridType = this.defaultGridType;

    // set the grid type on load
    if (this.hasGridButtonTarget) {
      this.gridType = this.gridButtonTarget.dataset.gridType;
    }
  }

  setGridType(e) {
    // set grid type on hidden field, submit form
    const target = e.currentTarget;

    // don't update if a user interacts with the button that is already selected
    if (this.gridType === target.dataset.gridType) return;

    this.gridType = target.dataset.gridType;
    this.hiddenGridTypeElTarget.value = this.gridType;
    this.sendFilterRequest();

    // toggle button class
    this.gridButtonTargets.forEach((button) => {
      if (button === target) {
        button.classList.add(this.buttonSelectedClass);
      } else {
        button.classList.remove(this.buttonSelectedClass);
      }
    });
  }

  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.id === 'per_page') {
      const perPageValue = dropdown.inputTarget.value;
      this.formTarget.querySelector('input[name="per_page"]').value = perPageValue;
    }
  }
}
