import { Controller } from '@hotwired/stimulus';
import AutoNumeric from 'autonumeric';

export default class extends Controller {
  static targets = ['input', 'inputGroup', 'errorText'];

  isCurrency = false;
  initialValue = null;

  connect() {
    // format currency fields on input and blur
    if (this.hasInputTarget) {
      this.initialValue = this.currentValue;
      if (this.inputTarget.dataset?.type === 'currency') {
        const anSettings = {
          'minimumValue': '0',
          'negativeSignCharacter': ' ',
          'dotDecimalCharCommaSeparator': ''
        };

        this.an = new AutoNumeric(this.inputTarget, anSettings);
        this.isCurrency = true;
      }
    }
  }

  get currentValue() {
    return this.inputTarget.value;
  }

  set currentValue(value) {
    if (this.isCurrency) this.an.set(value);
    else this.inputTarget.value = value;
  }

  get name() { return this.inputTarget.name; }

  resetValue(disable = false, clearError = false) {
    this.currentValue = this.initialValue;
    if (disable) this.disable();
    if (clearError) this.clearError();
  }

  setRequired(required) {
    if (required) this.inputTarget.setAttribute('required', true);
    else this.inputTarget.removeAttribute('required');
  }

  setError(message) {
    this.inputGroupTarget.classList.add('error');

    if (message.includes('contact support')) {
      // Replace plain text w/ link
      const tmp = document.createElement('div');
      tmp.innerHTML = message.replace(
        'contact support.',
        '<a href="/contact-support" class="body-semi-bold underline-hover">contact support.</a>'
      );
      this.errorTextTarget.innerHTML = tmp.innerHTML;
    } else {
      this.errorTextTarget.textContent = message;
    }

    // Prevent push from empty message
    if (!message) this.errorTextTarget.classList.add('hidden');
    else this.errorTextTarget.classList.remove('hidden');
  }

  clearError() {
    this.inputGroupTarget.classList.remove('error');
    this.errorTextTarget.textContent = '';
  }

  disable() {
    this.inputGroupTarget.classList.add('disabled');
    this.inputTarget.setAttribute('disabled', true);
  }

  enable() {
    this.inputGroupTarget.classList.remove('disabled');
    this.inputTarget.removeAttribute('disabled');
  }

  show() {
    this.inputGroupTarget.classList.remove('hidden');
    this.inputTarget.removeAttribute('hidden');
  }

  hide() {
    this.inputGroupTarget.classList.add('hidden');
    this.inputTarget.setAttribute('hidden', true);
  }

  validate(showError = true) {
    const input = this.inputTarget;
    const value = input.value.trim();
    const customErrorMsg = this.inputGroupTarget.dataset.errorText;
    let valid = true;
    let errorMsg = '';

    // Validate required field, set error and ignore remaining validation
    if (input.required && !value) {
      // Prevent input bouncing when custom error is an empty string
      if (showError) {
        if (customErrorMsg === '') this.setError('');
        else this.setError(customErrorMsg || 'Input required');
      }
      return false;
    }

    // If no value and input is not required, skip other validation
    if (!value && !input.required) return valid;

    // Validate special fields
    switch (input.dataset.type) {
      case 'email':
        if (!this.validateEmail(value)) {
          errorMsg = 'Please enter a valid email or contact support.';
          valid = false;
        }
        break;
      case 'phone':
        if (!this.validatePhone(value)) {
          errorMsg = 'Invalid phone number';
          valid = false;
        }
        break;
      case 'zip':
        if (!this.validateZip(value)) {
          errorMsg = 'Invalid zip code';
          valid = false;
        }
        break;
      default:
        break;
    }

    // Set error and allow empty custom errors
    if (!valid && showError) {
      this.setError(typeof customErrorMsg === 'undefined' ? errorMsg : customErrorMsg);
    } else this.clearError();

    return valid;
  }

  validatePhone(value) {
    const cleanedPhoneNumber = value.replace(/\D/g, '');
    return cleanedPhoneNumber.length === 10;
  }

  validateEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  validateZip(value) {
    const regex = /^\d{5}$/;
    return regex.test(value);
  }

  formatPhoneNumber(value) {
    const cleanedPhoneNumber = value.replace(/\D/g, '');
    const phoneNumberLength = cleanedPhoneNumber.length;

    if (phoneNumberLength < 4) return cleanedPhoneNumber;
    if (phoneNumberLength < 7) {
      return `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(3)}`;
    }

    const areaCode = `(${cleanedPhoneNumber.slice(0, 3)})`;
    return `${areaCode} ${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6, 10)}`;
  }

  handlePhoneInput() {
    const formattedValue = this.formatPhoneNumber(this.inputTarget.value);
    this.inputTarget.value = formattedValue;
  }

  handleNumberInput(e) {
    this.inputTarget.value = e.target.value.replace(/[^0-9]/g, '');
  }

  togglePassword(e) {
    const isHidden = this.inputTarget.type === 'password';
    this.inputTarget.type = isHidden ? 'text' : 'password';
    e.target.classList.remove(isHidden ? 'masked' : 'unmasked');
    e.target.classList.add(isHidden ? 'unmasked' : 'masked');
  }
}
