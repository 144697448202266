import { Controller } from '@hotwired/stimulus';

export default class LocationFormController extends Controller {
  static targets = ['editInputs', 'errors'];

  validate(e) {
    e.preventDefault();
    // Flag to track if any required field is empty
    let anyRequiredFieldEmpty = false;

    // Check all fields for emptiness and display error messages
    this.editInputsTargets.forEach((input) => {
      const isRequired = input.required;
      const fieldValue = input.value.trim();
      const fieldName = input.dataset.inputIdentifier;

      // Validation for required fields
      if (isRequired) {
        const errorElement = this.findErrorElement(fieldName);

        if (errorElement) {
          this.toggleErrorVisibility(errorElement, fieldValue === '');
          this.updateErrorMessage(input, fieldValue === '');

          // Set the flag if any required field is empty
          if (fieldValue === '') {
            anyRequiredFieldEmpty = true;
          }
        }
        if (fieldName === 'zip' && !this.isValidateUSZipCode(fieldValue)) {
          anyRequiredFieldEmpty = true;
          if (errorElement) {
            this.toggleErrorVisibility(errorElement, true);
            this.updateErrorMessage(input, true);
          }
        }
      }

      // Validation for non-required fields that are not empty
      if (!isRequired && fieldValue !== '') {
        const errorElement = this.findErrorElement(fieldName);
        let isValid = false;
        if (fieldName === 'phone') {
          isValid = this.isValidPhoneNumber(fieldValue);
        } else if (fieldName === 'email') {
          isValid = this.isValidEmail(fieldValue);
        }

        if (isValid) {
          // Input is now valid, so hide the error message
          if (errorElement) {
            this.toggleErrorVisibility(errorElement, false);
            this.updateErrorMessage(input, false);
          }
        } else {
          anyRequiredFieldEmpty = true;
          if (errorElement) {
            this.toggleErrorVisibility(errorElement, true);
            this.updateErrorMessage(input, true);
          }
        }
      }
    });

    // Submit the form only if no required fields are empty
    if (!anyRequiredFieldEmpty) {
      e.target.submit();
    }
  }

  // Helper function to find the error element by field name
  findErrorElement(fieldName) {
    return this.errorsTargets.find((error) => {
      return (
        error.dataset.locationFormTarget === 'errors'
        && error.dataset.inputIdentifier === fieldName
      );
    });
  }

  // Helper function to toggle error element visibility
  toggleErrorVisibility(element, shouldShow) {
    if (shouldShow) {
      element.classList.remove('hide');
    } else {
      element.classList.add('hide');
    }
  }

  updateErrorMessage(input, shouldShow) {
    if (shouldShow) {
      input.classList.add('error-input');
    } else {
      input.classList.remove('error-input');
    }
  }

  isValidateUSZipCode(zipCode) {
    const regex = /^\d{5}$/;
    return regex.test(zipCode);
  }

  isValidPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    return cleanedPhoneNumber.length === 10;
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
