import { Controller } from '@hotwired/stimulus';

/**
 * Controller class for Toggle component
 */
export default class extends Controller {
  static targets = ['label1', 'label2', 'toggle', 'toggleInput', 'toggleWrapper'];

  disabledClass = 'disabled';
  initialValue = null;

  get isDisabled() {
    if (!this.hasToggleInputTarget) return null;
    return this.toggleInputTarget.disabled;
  }

  connect() {
    if (this.hasToggleInputTarget) this.initialValue = this.currentValue;
  }

  // change classes on click
  toggleClass() {
    if (this.label1Target && this.label2Target) {
      this.label1Target.classList.toggle('hide');
      this.label2Target.classList.toggle('hide');
    }
  }

  resetValue(disable = false, clearError = false) {
    this.currentValue = this.initialValue;
    if (disable) this.disable();
    if (clearError) this.clearError();
  }

  get currentValue() {
    return this.toggleInputTarget.checked;
  }

  set currentValue(value) {
    this.toggleInputTarget.checked = value;
  }

  get name() { return this.toggleInputTarget.name; }

  // Placeholder for possible validation method
  // eslint-disable-next-line no-empty-function
  validate() { return true; }

  // Placeholder for clearing error state
  // eslint-disable-next-line no-empty-function
  clearError() {}

  enable() {
    if (this.hasLabel1Target) this.label1Target.classList.remove(this.disabledClass);
    if (this.hasLabel2Target) this.label2Target.classList.remove(this.disabledClass);
    this.toggleTarget.classList.remove(this.disabledClass);
    this.toggleWrapperTarget.classList.remove(this.disabledClass);
    this.toggleInputTarget.removeAttribute('disabled');
  }

  disable() {
    if (this.hasLabel1Target) this.label1Target?.classList.add(this.disabledClass);
    if (this.hasLabel2Target) this.label2Target?.classList.add(this.disabledClass);
    this.toggleTarget.classList.add(this.disabledClass);
    this.toggleWrapperTarget.classList.add(this.disabledClass);
    this.toggleInputTarget.setAttribute('disabled', true);
  }
}
