import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  modalContainerId = 'modal-container';
  static targets = ['modal'];

  // selector for finding focusable elements in the modal
  // eslint-disable-next-line max-len
  focusableElementsSelector = 'input:not([disabled]):not([style*=\'display: none\']), button:not([disabled]):not([style*=\'display: none\']), [href], [tabindex]:not([tabindex=\'-1\']), label[tabindex=\'0\']';

  hideModal() {
    if (this.hasModalTarget) {
      this.modalTarget.remove();
    } else {
      document.getElementById(this.modalContainerId).remove();
    }
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  // Find the first focusable element in the modal
  firstFocusableElement() {
    return this.modalTarget.querySelector(this.focusableElementsSelector);
  }

  // Find the last focusable element in the modal
  lastFocusableElement() {
    const focusableElements = this.modalTarget.querySelectorAll(this.focusableElementsSelector);
    return focusableElements[focusableElements.length - 1];
  }

  handleKeyPress(e) {
    if (e.code === 'Escape') {
      this.hideModal();
    }

    /**
     * Trap focus logic
     * So the user can't tab to elements outside the modal
     */
    const first = this.firstFocusableElement();
    const last = this.lastFocusableElement();

    // check if the Tab key was pressed
    if (e.key === 'Tab' && this.hasModalTarget) {
      // if Shift was also pressed and we're on the first element, go to the last
      if (e.shiftKey && document.activeElement === first) {
        e.preventDefault();
        last.focus();
        // otherwise if we're on the last element, go back to the first
      } else if (!e.shiftKey && document.activeElement === last) {
        e.preventDefault();
        first.focus();
      }
    }
  }

  handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
      this.hideModal();
    }
  }
}
