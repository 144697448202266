import { Controller } from '@hotwired/stimulus';

import DropdownItem from '../../../helpers/dropdownItem';
import parseHtmlCharCodes from '../../../helpers/parseHtmlCharCodes';

/**
 * Generic controller class for Dropdown menus
 */
export default class Dropdown extends Controller {
  static targets = [
    'controller',
    'countBadge',
    'emptySearch',
    'emptyOptions',
    'errorText',
    'input',
    'item',
    'list',
    'search',
    'searchWrapper',
    'selection',
    'selectAll',
    'selectAllCheckbox',
    'toggle',
    'wrapper',
    'turboFrame'
  ];

  static values = {
    placeholder: String,
    dependent: String,
    error: { type: Boolean, default: false },
    stream: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  };

  // Class wide custom events
  static events = {
    ready: 'dropdown-ready',
    reload: 'dropdown-reload',
    blur: 'dropdown-blur',
    close: 'dropdown-close',
    enable: 'dropdown-enable',
    disable: 'dropdown-disable',
    update: 'dropdown-update',
  };

  // Control vars
  multiselect = false;
  searchable = false;
  useKeys = false;
  isOpen = false;
  hasDependent = false;
  arrowIconElement = '';
  initializing = true;
  id = '';

  // Css
  placeholderClass = 'dropdown-placeholder';
  hiddenClass = 'hidden';
  openClass = 'open';

  itemTree = {};
  selection = [];
  lastOpenedSelection = [];
  previousSelection = [];
  dependentElement = null;

  // loading vars
  loading = false;
  pendingTargets = [];
  loadingCheckIntervalId = '';

  /**
   * Returns the item tree values as an array
   * @returns {(DropdownItem[] | [])}
   */
  get items() {
    return Object.values(this.itemTree) || [];
  }

  get currentValue() {
    return this.inputTarget.value;
  }

  get name() {
    return this.inputTarget.name;
  }

  // Controller has been connected to an element
  connect() {
    // Find icon to animate
    this.arrowIconElement = this.toggleTarget.querySelector('.icon');

    // Grab the dependent element
    if (this.dependentValue) {
      this.hasDependent = true;
      this.dependentElement = document.querySelector(`div.dropdown#${this.dependentValue}`);
    }

    // Get control data
    const dataset = this.controllerTarget.dataset;
    this.multiselect = dataset?.dropdownMultiselect === 'true';
    this.searchable = dataset?.dropdownSearchable === 'true';
    this.useKeys = dataset?.dropdownUseKeys === 'true';
    this.id = this.controllerTarget.id;

    this.buildItemTree();

    this.updateSelection();

    this.updateInputTarget();

    const eventDetail = { dropdown: this };

    this.initializing = false;
    // Dropdown has been connected. Tell the world (parent form)
    this.inputTarget.dispatchEvent(new CustomEvent(Dropdown.events.ready, { detail: eventDetail }));
  }

  turboFrameTargetConnected(target) {
    // Check if lazy loading frame has been connected
    if (!target.hasAttribute('complete') && target.hasAttribute('src')) {
      this.setLoading(true);
      target.loaded.then(() => {
        this.reload(null);
      });
    }
  }

  itemTargetConnected(target) {
    if (this.loading && !this.initializing) this.pendingTargets.push(target);
  }

  buildItemTree() {
    // Reset the tree
    this.itemTree = {};

    // Add each item to the tree and assign relatives
    this.itemTargets.forEach((item) => {
      const parentId = item.dataset.dropdownParentId;
      const node = new DropdownItem(item);
      if (typeof parentId !== 'undefined' && parentId in this.itemTree) {
        node.parent = this.itemTree[parentId];
        this.itemTree[parentId].addChild(node);
      }

      this.itemTree[node.element.id] = node;
    });

    // Verify node states
    this.items.forEach((node) => {
      if (node.selected || node.partial) return;

      // Partial select if some selected children
      if (node.children.some((child) => { return child.selected; })) {
        node.partialSelect(true);
      }

      // Parent is selected so select the child
      if (node.parent && node.parent.selected) node.select(false);
    });
  }

  updateSelection() {
    // Build new selection
    const seenKeys = [];
    const newSelection = this.items.reduce((arr, node) => {
      if (node.selected && !seenKeys.includes(node.key)) {
        // Don't track undefined keys
        if (typeof node.key !== 'undefined') seenKeys.push(node.key);
        if (!node.parent?.selected) arr.push(node);
      }
      return arr;
    }, []);

    this.previousSelection = this.selection;
    this.selection = newSelection;

    if (!this.selection.length) {
      // Empty Selection
      this.countBadgeTarget.classList.add(this.hiddenClass);
      this.countBadgeTarget.textContent = '';
      this.selectionTarget.classList.add(this.placeholderClass);
      this.selectionTarget.textContent = this.placeholderValue;
    } else if (this.selection.length === 1) {
      // Single item selected
      this.selectionTarget.classList.remove(this.placeholderClass);
      const label = this.selection[0].overrideSelectedLabel || this.selection[0].label;
      this.selectionTarget.textContent = parseHtmlCharCodes(label);
      this.countBadgeTarget.classList.add(this.hiddenClass);
      this.countBadgeTarget.textContent = '';
    } else {
      // Multi item selected
      this.countBadgeTarget.classList.remove(this.hiddenClass);
      this.selectionTarget.classList.remove(this.placeholderClass);
      this.selectionTarget.textContent = parseHtmlCharCodes(
        this.selection.map((item) => { return item.label; }).join(', ')
      );
      this.countBadgeTarget.textContent = this.selection.length;
    }

    // Update select all target
    if (this.hasSelectAllTarget && this.hasSelectAllCheckboxTarget) {
      if (this.items.every((item) => { return item.selected; })) {
        this.selectAllCheckboxTarget.checked = true;
      } else {
        this.selectAllCheckboxTarget.checked = false;
      }
    }
  }

  updateInputTarget() {
    if (!this.hasInputTarget) return;
    this.inputTarget.value = this.selection.map((sel) => {
      if (this.useKeys) return sel.key || sel.value;
      return sel.value || '';
    }).join(',');

    // Initializing... Prevent normal update
    if (this.initializing) return;

    const eventDetail = { dropdown: this };
    this.inputTarget.dispatchEvent(
      new CustomEvent(Dropdown.events.update, { detail: eventDetail }),
    );
  }

  /**
   * Updates selection array with the selected elements data
   *
   * @params {object} e: Target of click event
  */
  itemClicked(e) {
    // if this was called via keypress, only respond to the Enter key
    // we have to do this because keydown.enter doesn't seem to work on these li elements
    if (e instanceof KeyboardEvent && e.key !== 'Enter') {
      return;
    }

    if (e.target.dataset.dropdownIgnore === 'true') return;

    /** @type {DropdownItem} */
    const treeNode = this.itemTree[e.currentTarget.id];
    if (!treeNode) {
      // We were not able to get the data attributes to make a selection
      throw Error('Could not find option in tree');
    }

    // Deselect the previous item
    if (!this.multiselect && this.selection[0]) {
      /**
       * NOTE:: Could return here to prevent update event when the same item is clicked
       * Alternatively, we could clear the selection when
       * the item is selected again on a single select dropdown
       */
      this.selection[0].deselect();
      this.selection = [];
    }

    /** Triggers the process for item, parent, and ALL children
     * These functions then internally update their relatives recursively
     */
    if (treeNode.selected) treeNode.deselect();
    else treeNode.select();

    // Find possible duplicates and update their selection status to match clicked element
    this.items.forEach((item) => {
      if (item === treeNode) return;
      if (item.key === treeNode.key && typeof item.key !== 'undefined') {
        if (!treeNode.selected) item.deselect();
        else item.select();
      }
    });

    this.updateSelection();
    this.updateInputTarget();

    if (this.multiselect) e.stopPropagation(); // Prevent closing the menu on click
  }

  /**
   * Open the dropdown menu
   *
   * NOTE: Overrides of this method will still execute when disabled
  */
  open() {
    // Store open state then close all dropdowns to prevent overlaps
    const wasOpen = this.isOpen;
    const dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach((dropdown) => {
      dropdown.dispatchEvent(new CustomEvent(Dropdown.events.close));
    });

    if (this.disabledValue) return;

    // Prevent re-opening if the dropdown was already open
    if (wasOpen) return;

    // previousSelection is updated on every change
    this.lastOpenedSelection = this.selection;

    // Open dropdown
    this.isOpen = true;
    this.toggleTarget.classList.add(this.openClass);
    this.wrapperTarget.classList.add(this.openClass);
    this.listTarget.classList.add(this.openClass);
    if (this.arrowIconElement) this.arrowIconElement.classList.add(this.openClass);

    // Reset list to top
    this.listTarget.scrollTo({ top: 0 });
  }

  // Close the dropdown menu
  close() {
    // Already closed, ignore
    if (!this.isOpen) return;

    // Close the dropdown
    this.isOpen = false;
    this.toggleTarget.classList.remove(this.openClass);
    this.wrapperTarget.classList.remove(this.openClass);
    this.listTarget.classList.remove(this.openClass);
    if (this.arrowIconElement) this.arrowIconElement.classList.remove(this.openClass);

    const added = this.selection.filter((item) => {
      return !this.lastOpenedSelection.includes(item);
    });

    const removed = this.lastOpenedSelection.filter((item) => {
      return !this.selection.includes(item);
    });

    // Reset search values
    this.resetSearch();
    const eventDetail = {
      dropdown: this,
      prevSelection: this.lastOpenedSelection,
      wasChanged: !!(added.length || removed.length),
      added,
      removed
    };
    this.inputTarget.dispatchEvent(
      new CustomEvent(Dropdown.events.blur, { detail: eventDetail }),
    );

    // TODO: Possibly update how dependent elements are rendered using turbo over custom events
    // Idea would be to just update the src attribute on the dependent to load new options via turbo load strategy
    // One possible issue is the idea of retaining selections between renders (If that is even needed)
    // ! If this is updated, we must revisit featured_category_form_controller.js
    // console.log(this.dependentElement, `${this.inputTarget.name}: ${this.inputTarget.value}`);
  }

  // Filters the dropdown
  search() {
    const searchTerm = this.searchTarget.value?.toLowerCase();

    const results = this.items.filter((item) => {
      if (item.label?.toLowerCase().includes(searchTerm) || !searchTerm) {
        item.show();
        return true;
      }
      item.hide();
      return false;
    });

    // Results were found, hide no results text and show select all for multiselect
    if (results.length) this.emptySearchTarget.classList.add(this.hiddenClass);
    else this.emptySearchTarget.classList.remove(this.hiddenClass);
  }

  // Reset search input and reveals all filtered results
  resetSearch() {
    if (!this.hasSearchTarget) return;
    this.searchTarget.value = '';
    this.items.forEach((item) => { item.show(); });
    if (this.hasEmptySearchTarget) this.emptySearchTarget.classList.add(this.hiddenClass);
  }

  /**
   * Sets loading flag and updates CSS state
   * @param {boolean} loading
   */
  setLoading(loading) {
    // Can use this to set a loading CSS state
    this.loading = loading;
  }

  /**
   * Reload dropdown item data.
   * Checks for newly added items then reloads itemTree once all are connected
   */
  reload(e) {
    this.resetSearch();
    // Make sure loading flag is set for status checker
    // Not using setLoading in case we don't want to show loading state
    this.loading = true;
    this.loadingCheckIntervalId = window.setInterval(
      this.checkLoadingStatus.bind(this, e?.detail?.enable),
      50,
    );
  }

  /**
   * Checks if any targets have been connected since last check
   *
   * If not, clears the reloading flag and updates tree
   * @param {boolean} [enable=true] Should the dropdown be enabled when complete?
   */
  checkLoadingStatus(enable = true) {
    // Finished loading, clear interval and update data
    if (!this.loading) {
      window.clearInterval(this.loadingCheckIntervalId);
      // Rebuild data
      this.buildItemTree();
      this.updateSelection();
      this.updateInputTarget();
      // Enable dropdown
      if (enable) this.enable();

      if (this.items.length) {
        this.emptyOptionsTarget.classList.add(this.hiddenClass);
        if (this.multiselect) this.selectAllTarget.classList.remove(this.hiddenClass);
        if (this.searchable) this.searchWrapperTarget.classList.remove(this.hiddenClass);
      } else {
        this.emptyOptionsTarget.classList.remove(this.hiddenClass);
        if (this.multiselect) this.selectAllTarget.classList.add(this.hiddenClass);
        if (this.searchable) this.searchWrapperTarget.classList.add(this.hiddenClass);
      }
      return;
    }

    // Store if new targets were found, then reset pending list
    const newTargets = this.pendingTargets.length;
    this.pendingTargets = [];

    // New items found, keep the interval going
    if (!newTargets) this.setLoading(false);
  }

  // General validation
  validate(showError = true) {
    const errorMessage = this.controllerTarget.dataset.errorText;

    if (this.inputTarget.required && !this.selection.length) {
      if (showError) {
        this.errorValue = true;
        if (this.hasErrorTextTarget) {
          this.errorTextTarget.textContent = errorMessage || 'Please make a selection';
        }
      }
      return false;
    }

    this.errorValue = false;
    if (this.hasErrorTextTarget) {
      this.errorTextTarget.textContent = '';
    }
    return true;
  }

  setCustomError(message = '') {
    this.errorValue = true;
    if (this.hasErrorTextTarget) {
      this.errorTextTarget.textContent = message;
    }
  }

  clearCustomError() {
    this.errorValue = false;
    if (this.hasErrorTextTarget) {
      this.errorTextTarget.textContent = '';
    }
  }

  // Clears error state
  errorValueChanged() {
    if (this.errorValue) {
      this.controllerTarget.classList.add('error');
    } else {
      this.controllerTarget.classList.remove('error');
      if (this.hasErrorTextTarget) {
        this.errorTextTarget.textContent = '';
      }
    }
  }

  // Enables the dropdown
  enable() {
    this.disabledValue = false;
    this.inputTarget.disabled = false;
    this.controllerTarget.classList.remove('disabled');
    this.toggleTarget.setAttribute('tabindex', '0');
    this.resetSearch();
  }

  // Disables the dropdown
  disable() {
    this.disabledValue = true;
    this.inputTarget.disabled = true;
    this.controllerTarget.classList.add('disabled');
    this.toggleTarget.setAttribute('tabindex', '-1');
    this.resetSearch();
  }

  /**
   * Manually select an item
   * @param {string} id Item tree id
   */
  selectItemById(id) {
    const item = this.itemTree[id];
    if (!item) return;

    item.select();
    this.updateSelection();
    this.updateInputTarget();
  }

  /**
   * Manually deselect an item
   * @param {string} id Item tree id
   */
  deselectItemById(id) {
    const item = this.itemTree[id];
    if (!item) return;

    item.deselect();
    this.updateSelection();
    this.updateInputTarget();
  }

  // Adds all targets to selection array
  selectAll(e) {
    if (!this.multiselect) return;
    if (e && (e.key !== 'Enter' && e.type !== 'click')) return;

    // Filter selection list by search value if present
    let filteredList = this.items;

    if (this.hasSearchTarget && this.searchTarget.value) {
      filteredList = filteredList.filter((item) => {
        return item.label?.includes(this.searchTarget.value.toLowerCase());
      });
    }

    // Deselect if all items are selected
    const deselect = filteredList.every((item) => {
      return item.selected;
    });

    filteredList.forEach((item) => {
      if (deselect) item.deselect();
      else item.select();
    });

    this.updateSelection();
    this.updateInputTarget();
  }

  /** Get first selection or selection at provided index
   *
   * @param {Number} idx Selection index, defaults to 0 (latest selection)
   *
   * @returns {object | null} Single selection value or null if the selection does not exist
   */
  getSelection(idx = 0) {
    if (!this.selection?.length) return null;
    if (typeof this.selection?.[idx] === 'undefined') return null;
    return this.selection[idx];
  }

  // Remove element from selection by id
  removeById(id) {
    const item = this.itemTree[id];
    if (!item || !item.selected) return;
    item.deselect();
    this.updateSelection();
  }

  /**
   * Wrapper of {@link dispatchCustomEvent} for dispatching events to dependentElement
   *
   * @param {string} event Event to dispatch
   * @param {object} detail Additional details
   */
  updateDependent(event, detail = {}) {
    if (!event || !this.dependentElement) return;
    this.dispatchCustomEvent(event, detail, this.dependentElement);
  }

  /**
   * Dispatch a custom dropdown event
   * @param {string} event Name of event to call
   * @param {object=} eventDetail Additional detail for event
   * @param {HTMLElement=} element Target for dispatch, defaults to the controllerTarget
   */
  dispatchCustomEvent(event, eventDetail = {}, element = this.controllerTarget) {
    if (!element || !event) return;

    const detail = {
      ...eventDetail,
      origin: this,
    };

    element.dispatchEvent(new CustomEvent(event, { detail }));
  }

  // Empty method for stopping propagation of events that don't need an action
  // TODO: Possibly create a BaseController to extend with methods like this... May not be as useful as it sounds
  // eslint-disable-next-line no-empty-function
  stopProp() { }
}
