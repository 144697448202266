import { Controller } from '@hotwired/stimulus';

/**
 * Controller class for Card component with single Toggle
 */
export default class extends Controller {
  static targets = ['card'];

  // Card with Toggle
  toggleClass() {
    if (this.cardTarget) {
      this.cardTarget.classList.toggle('active');
    }
  }

  // Card with Toggle and Input
  toggleInputClass() {
    const cardContainerTarget = this.cardTarget.closest('.card-input');
    const labelTarget = this.cardTarget.getElementsByClassName('label-text')[0];
    const labelTarget2 = this.cardTarget.getElementsByClassName('label-text-alt')[0];
    const inputGroupTarget = cardContainerTarget.getElementsByClassName('input-group')[0];
    const inputTarget = cardContainerTarget.getElementsByClassName('input')[0];

    cardContainerTarget.classList.toggle('active');
    labelTarget.classList.toggle('hide');
    labelTarget2.classList.toggle('hide');

    inputTarget.disabled = !inputTarget.disabled;

    if (inputTarget.disabled) {
      inputGroupTarget.classList.add('disabled');
    } else {
      inputGroupTarget.classList.remove('disabled');
    }
  }
}
