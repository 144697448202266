import { Controller } from '@hotwired/stimulus';

export default class ToggleInputController extends Controller {
  disabledClass = 'disabled';

  static targets = ['toggleInput', 'toggle', 'input'];

  connect() {
    // ensure the state of inputTarget matches toggleTarget
    this.toggle();
  }

  // enable/disable inputTarget when toggleTarget is clicked
  toggle() {
    const disabled = !this.toggleInputTarget.checked;

    this.inputTarget.disabled = disabled;

    if (disabled) {
      this.toggleTarget.classList.add(this.disabledClass);
    } else {
      this.toggleTarget.classList.remove(this.disabledClass);
    }
  }
}
