import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // set debug to true in order to visualize the intersection observer
  debug = false;
  debugScriptSrc = 'https://unpkg.com/intersection-observer-debugger';

  // currently active tab
  activeTab;

  // page sections being observed
  pageSections = [];

  // intersection observer
  observer;

  // num of scroll events fired
  scrollEvents = 0;

  // abort controller for scroll events
  abortController;

  // css
  activeClass = 'tab-link-active';

  // targets
  static targets = ['tab'];

  connect() {
    // load debug script if debug is true
    // otherwise just init everything
    if (this.debug) {
      this.loadDebugScript()
        .then(() => {
          this.init();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    } else {
      this.init();
    }
  }

  init() {
    const url = window.location.href;
    const tabs = this.tabTargets;

    // NOTE: the values we're using are ok, but here are some
    // more in case we want to change functionality in the future
    // 360 works for catalog settings
    // 360 works for products
    // 355 works for custom products
    const rootMarginTopOffset = 355;
    const windowHeight = window.innerHeight;

    // NOTE: the values we're using are ok, but here are some
    // more in case we want to change functionality in the future
    // 20 works for catalog settings
    // 60 works for products
    // 65 works for custom products
    const observerViewportHeight = 65;

    // essentially the rootMarginTopOffset plus observerViewportHeight
    // but calculated from the bottom of the window
    const rootMarginBottomOffset = (windowHeight - rootMarginTopOffset) - observerViewportHeight;

    // observer options
    // NOTE: it's possible to create different options for each page section being observed
    const options = { rootMargin: `${-rootMarginTopOffset}px 0px ${-rootMarginBottomOffset}px 0px` };

    // create the observer
    this.observer = this.createObserver(options);

    // loop through the tabs and click the one that corresponds to the current URL
    // allows for deep linking to tabs and applying the active status to that tab
    tabs.forEach((tab, i) => {
      // get the original URL value
      const href = tab.getAttribute('href');

      // get active string
      const isActive = tab.dataset.active;

      // page sections correlate to the tab url
      const pageSection = document.getElementById(href.replace('#', ''));

      // assign data-id and use to determine which tab to highlight
      // based on observer intersections
      pageSection.dataset.id = i;
      this.pageSections.push(pageSection);

      // if the erb var instructs the tab to be active
      if (isActive === 'true' && !this.activeTab) {
        this.selectActiveTab(this.tabTargets[i]);
      }

      // if there is a hash in the page url, set corresponding tab to active
      // this will override the 'active' erb var
      if (url.includes(href)) {
        this.deselectActiveTab();
        this.selectActiveTab(this.tabTargets[i]);
        this.activeTab.click();
      }
    });

    // start scroll event listener
    this.addScrollEventListener();
  }

  loadDebugScript() {
    // write debug script tag to document head
    return new Promise((resolve, reject) => {
      try {
        const el = document.createElement('script');
        el.src = this.debugScriptSrc;

        el.addEventListener('load', () => {
          resolve({ loaded: true, error: false });
        }, { once: true });

        el.addEventListener('error', () => {
          reject(new Error('Error loading debug script'));
        }, { once: true });

        document.head.appendChild(el);
      } catch (error) {
        reject(error);
      }
    });
  }

  createObserver(options) {
    // create intersection observer
    return new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const intersecting = entry.isIntersecting;

        // change div background colors when debugging
        if (this.debug) {
          const { target } = entry;
          target.style.backgroundColor = intersecting ? 'blue' : 'orange';
        }

        if (intersecting) {
          this.deselectActiveTab();

          // highlight corresponding tab link
          this.selectActiveTab(this.tabTargets[entry.target.dataset.id]);
        }
      });
    }, options);
  }

  deselectActiveTab() {
    if (this.activeTab) {
      this.activeTab.classList.remove(this.activeClass);
    }
  }

  selectActiveTab(tab) {
    tab.classList.add(this.activeClass);
    this.activeTab = tab;
  }

  addScrollEventListener() {
    this.abortController = new AbortController();
    window.addEventListener(
      'scroll',
      this.scrollListener.bind(this),
      { signal: this.abortController.signal },
    );
  }

  removeScrollEventListener() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  scrollListener() {
    // using the number 5 because clicking the nav causes
    // a scroll event, so this gives a little bit of padding
    // before the event is removed
    if (this.scrollEvents > 5) {
      // ensure observers are disconnected
      this.observer.disconnect();

      // observe all page sections
      this.pageSections.forEach((section) => {
        this.observer.observe(section);
      });

      // reset scrollEvents count and stop scroll listener
      this.scrollEvents = 0;
      this.removeScrollEventListener();
    } else {
      this.scrollEvents += 1;
    }
  }

  // click event for the tabs
  tabClick(e) {
    this.removeScrollEventListener();

    // stop all observers
    this.observer.disconnect();

    this.deselectActiveTab();

    /*
      NOTE: there was a request that when a user
      clicks the first tab, it would scroll to the top
      of the page. this handles that request
    */
    if (e.currentTarget === this.tabTargets[0]) {
      e.preventDefault();
      window.location.assign(e.currentTarget.href);
      window.scrollTo({ top: 0, behavior: 'instant' });
    }

    this.selectActiveTab(e.currentTarget);

    this.addScrollEventListener();
  }
}
