import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  secondsToClose = 6 * 1000;
  toastRootClass = '.toast-root';
  static targets = ['toast'];

  connect() {
    this.closeTimeout = setTimeout(() => {
      this.hideToast();
    }, this.secondsToClose);
  }

  disconnect() {
    clearTimeout(this.closeTimeout);
    this.toastRoot.removeEventListener('animationend', this.removeToast);
  }

  hideToast() {
    // animate
    this.toastRoot = document.querySelector(this.toastRootClass);
    this.toastRoot.classList.add('fade-out');

    // add listener
    this.toastRoot.addEventListener('animationend', this.removeToast.bind(this));
  }

  removeToast() {
    // remove from dom
    this.toastTarget.remove();
  }

  handleKeyPress(e) {
    if (e.code === 'Escape') {
      this.hideToast();
    }
  }

  handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
      this.hideToast();
    }
  }
}
