import FileUploadController from './file_upload_controller';

export default class ProductImageController extends FileUploadController {
  // keep track of current step
  currStep = 0;

  // target doesn't always match step
  // because there are less targets than steps
  currTarget = 0;

  static values = {
    imageSrc: String,
  };

  connect() {
    // keep track of current step
    // if imageSrc is provided, we start on the last step
    this.currStep = this.imageSrcValue ? this.stepTargets.length - 1 : 0;

    // there are 3 steps but only 2 fileTargets, 2 errorMessageTargets
    // so current target will be different
    this.currTarget = this.currStep === this.stepTargets.length - 1 ? 1 : 0;

    // hide all steps
    this.stepTargets.forEach((step) => {
      step.classList.add(this.hiddenClass);
    });

    // show the step we want
    this.stepTargets[this.currStep].classList.remove(this.hiddenClass);

    // if there is an imageSrcValue provided, we know we need
    // to show it on the second previewImageTarget
    this.previewImageTargets[1].src = this.imageSrcValue || '';
  }

  handleFileInputChange() {
    const shouldShowNextStep = super.canUploadFile();
    if (shouldShowNextStep) {
      // hide the current step
      this.stepTargets[this.currStep].classList.add(this.hiddenClass);

      // always show step 1 and the first preview image
      this.stepTargets[1].classList.remove(this.hiddenClass);
      this.previewImageTargets[0].src = URL.createObjectURL(this.file);
    }
  }
}
