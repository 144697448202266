/* eslint-disable max-len */
import BannerController from './banner_controller';
import { Turbo } from '@hotwired/turbo-rails';

export default class StorefrontsController extends BannerController {
  static targets = [
    'toggleInput',
    'checkbox',
    'form',
    'welcomeMessage',
    'errorMessage',
    'submitButton',
    'placeholderImage',
    'previewImage'
  ];

  openModalAt(url) {
    const selectedIds = this.checkboxTargets
      .filter((checkbox) => { return checkbox.checked; })
      .map((checkbox) => { return checkbox.name; });

    if (selectedIds.length > 0) {
      Turbo.visit(`${url}?locations=${selectedIds.join(',')}`, { frame: 'modal' });
    }
  }

  openModal(e) {
    e.preventDefault();

    const url = e.currentTarget.dataset.url;
    const type = e.currentTarget.dataset.type;
    this.openModalAt(url, type);
  }

  validateWelcomeMessage(e) {
    const message = e.detail.content;
    const invalidCharactersRegex = /[^\w\s!@#$%^&*()[\]{}|\\:;"'<,>.?~/`=\-+]/;

    // check if there are emojis in the text
    if (invalidCharactersRegex.test(message)) {
      this.welcomeMessageTarget.classList.add('welcome-message-field-error');
      this.errorMessageTarget.classList.remove('hidden');
      this.submitButtonTarget.disabled = true;
    } else {
      // otherwise clear error
      this.welcomeMessageTarget.classList.remove('welcome-message-field-error');
      this.errorMessageTarget.classList.add('hidden');
      this.submitButtonTarget.disabled = false;
    }
  }

  handleFileInputChange() {
    const shouldShowNextStep = super.canUploadFile();
    if (shouldShowNextStep) {
      // set preview image
      this.previewImageTarget.src = URL.createObjectURL(this.file);

      // hide placeholder
      this.placeholderImageTarget.classList.add('hidden');

      // show attached image
      this.previewImageTarget.classList.remove('hidden');

      // hide the current step
      this.stepTargets[0].classList.add(this.hiddenClass);

      // show the second step
      this.stepTargets[1].classList.remove(this.hiddenClass);
    }
  }
}
