/* eslint-disable camelcase */
import { Controller } from '@hotwired/stimulus';

// Import TinyMCE
import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model';

// Import icons
import 'tinymce/icons/default/icons';

// Import theme
import 'tinymce/themes/silver/theme';
// import 'tinymce/skins/ui/oxide/skin.min';
// import 'tinymce/skins/ui/oxide/content.min';

// Import plugins
// import 'tinymce/plugins/advlist'
// import 'tinymce/plugins/anchor'
// import 'tinymce/plugins/autolink'
// import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/autosave'
// import 'tinymce/plugins/charmap'
// import 'tinymce/plugins/code';
// import 'tinymce/plugins/codesample'
// import 'tinymce/plugins/directionality'
// import 'tinymce/plugins/emoticons'
// import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/help';
// import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss';
// import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link';
// import 'tinymce/plugins/lists';
// import 'tinymce/plugins/media'
// import 'tinymce/plugins/nonbreaking'
// import 'tinymce/plugins/pagebreak'
// import 'tinymce/plugins/preview';
// import 'tinymce/plugins/quickbars'
// import 'tinymce/plugins/save'
// import 'tinymce/plugins/searchreplace'
// import 'tinymce/plugins/table';
// import 'tinymce/plugins/template'
// import 'tinymce/plugins/visualblocks'
// import 'tinymce/plugins/visualchars'
// import 'tinymce/plugins/wordcount';

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this.defaults = {
      skin: false,
      selector: '.tinymce',
      height: 350,
      menubar: false,
      plugins: [
        'link',
        'importcss',
      ],
      paste_block_drop: true, // disallow dragging/dropping/pasting images into the editor
      toolbar: 'undo redo | bold italic strikethrough | link | styles | removeformat',
      formats: {
        h1: { selector: 'p', attributes: { class: 'typography-heading-900' } },
        h2: { selector: 'p', attributes: { class: 'typography-heading-800' } },
        h3: { selector: 'p', attributes: { class: 'typography-heading-700' } },
        h4: { selector: 'p', attributes: { class: 'typography-heading-600' } },
        h5: { selector: 'p', attributes: { class: 'typography-heading-500' } },
        h6: { selector: 'p', attributes: { class: 'typography-heading-400' } },
        body: { selector: 'p', attributes: { class: 'typography-body' } },
        bodyMedium: { selector: 'p', attributes: { class: 'typography-body-medium' } },
        bodyBold: { selector: 'p', attributes: { class: 'typography-body-bold' } },
        small: { selector: 'p', attributes: { class: 'typography-small' } },
        smallMedium: { selector: 'p', attributes: { class: 'typography-small-medium' } },
        smallBold: { selector: 'p', attributes: { class: 'typography-small-bold' } },
        eyebrow: { selector: 'p', attributes: { class: 'typography-eyebrow' } },
        eyebrowMedium: { selector: 'p', attributes: { class: 'typography-eyebrow-medium' } },
        eyebrowUppercase: { selector: 'p', attributes: { class: 'typography-eyebrow-uppercase' } },
      },
      style_formats: [
        { title: 'Headings',
          items: [
            { title: 'Heading 1', format: 'h1', block: 'h1' },
            { title: 'Heading 2', format: 'h2', block: 'h2' },
            { title: 'Heading 3', format: 'h3', block: 'h3' },
            { title: 'Heading 4', format: 'h4', block: 'h4' },
            { title: 'Heading 5', format: 'h5', block: 'h5' },
            { title: 'Heading 6', format: 'h6', block: 'h6' },
          ] },
        { title: 'Body', format: 'body' },
        { title: 'Body Medium', format: 'bodyMedium' },
        { title: 'Body Bold', format: 'bodyBold' },
        { title: 'Small', format: 'small' },
        { title: 'Small Medium', format: 'smallMedium' },
        { title: 'Small Bold', format: 'smallBold' },
        { title: 'Eyebrow', format: 'eyebrow' },
        { title: 'Eyebrow Medium', format: 'eyebrowMedium' },
        { title: 'Eyebrow Uppercase', format: 'eyebrowUppercase' },
      ],
      style_formats_merge: false,
      importcss_merge_classes: false,
      importcss_append: true,
      importcss_selector_filter: '--none--', // hack to prevent imported CSS from getting added to formats menu
      // newline_behavior: 'invert', // causes issues with heading tags
      branding: false,
      statusbar: false,
      // Sabretooth typography CSS
      content_css: '/tinymce-styles.css',
      // validation, only for modal-welcome-message
      setup: (editor) => {
        editor.on('input', () => {
          const element = document.getElementById('modal-welcome-message');
          if (element) {
            const controller = this.application.getControllerForElementAndIdentifier(element, 'storefronts');
            controller.validateWelcomeMessage({ detail: { content: editor.getContent() } });
          }
        });
      },
    };
  }

  connect() {
    // Initialize TinyMCE
    const config = { ...this.defaults, target: this.inputTarget };
    tinymce.init(config);
  }

  disconnect() {
    tinymce.remove();
  }
}
