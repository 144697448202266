import FormController from './components/form_controller';

export default class extends FormController {
  formCheckTimeout = 250;
  formCheckTimeoutId = null;
  initialFormData = [];
  isDirty = false;

  static targets = [
    'saveButton',
    'rowTemplate',
    'tbody',
    'dropdown',
    'emptyRowTemplate',
    'emptyRow',
  ];

  // fires when the controller is attached
  connect() {
    // give all elements a moment to populate before checking initial values
    setTimeout(() => {
      this.initialFormData = this.getFormData();
    }, 100);
  }

  // returns an object with all form values
  getFormData() {
    const formData = [];
    const tbody = this.tbodyTarget;
    const rows = tbody.querySelectorAll('tr');
    rows.forEach((row) => {
      // don't bother checking the empty row
      if (row.classList.contains('empty-row')) {
        return;
      }

      const date = row.querySelector('.datepicker .date')?.value || null;
      const isOpen = row.querySelector('input[type=checkbox]')?.checked || false;
      const dropdowns = row.querySelectorAll('.dropdown');
      const openTime = dropdowns[0]?.querySelector('input[type=hidden]')?.value || '';
      const closeTime = dropdowns[1]?.querySelector('input[type=hidden]')?.value || '';
      const note = row.querySelector('.text')?.value || '';
      formData.push({
        date,
        isOpen,
        openTime,
        closeTime,
        note,
      });
    });
    return formData;
  }

  // enables/disables a row's open/close time dropdowns
  // based on the state of the open/close toggle switch
  toggleOpen(e) {
    const row = e?.target?.closest('tr'); // the current row
    const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
    const toggleWrapper = row?.querySelector('.input-toggle'); // the wrapper for the toggle
    const toggle = toggleWrapper?.querySelector('input[type=checkbox]'); // the toggle input itself
    const checked = toggle?.checked; // whether it's checked or not

    // loop through the dropdowns and enable or disable them
    dropdowns?.forEach((d) => {
      const controller = this.getControllerByElement(d);
      if (checked) {
        controller.enable();
      } else {
        controller.disable();
      }
    });
  }

  // debounce for startCheckSaveButtonState() below
  checkSaveButtonState() {
    if (this.formCheckTimeoutId) clearTimeout(this.formCheckTimeoutId);
    this.formCheckTimeoutId = setTimeout(() => {
      this.startCheckSaveButtonState();
    }, this.formCheckTimeout);
  }

  // sets the save button enabled or disabled based on form state
  startCheckSaveButtonState() {
    // check the form to see if it's unfinished
    const tbody = this.tbodyTarget;
    const rows = tbody.querySelectorAll('tr');
    const rowChecks = [];
    rows.forEach((row) => {
      // don't bother checking the empty row
      if (row.classList.contains('empty-row')) {
        rowChecks.push(false);
        return;
      }
      // normal rows can be checked
      const hasDate = !!row.querySelector('.datepicker .date').value;
      const isOpen = !!row.querySelector('input[type=checkbox]').checked;
      const hasOpen = !!row.querySelector('input[id$=open-time-dropdown-input]').value;
      const hasClose = !!row.querySelector('input[id$=close-time-dropdown-input]').value;
      const hasHours = isOpen ? (hasOpen && hasClose) : true;
      const hasDesc = !!row.querySelector('.text').value;
      rowChecks.push(hasDate && hasHours && hasDesc);
    });
    const formComplete = !rowChecks.some((r) => { return r === false; });

    // check if the form is dirty
    this.checkFormIsDirty();

    // enable or disable the save button
    this.saveButtonTarget.disabled = !(formComplete && this.isDirty);
  }

  // checks if the form values have changed from the initial state
  checkFormIsDirty() {
    const formData = this.getFormData();
    this.isDirty = JSON.stringify(formData) !== JSON.stringify(this.initialFormData);
  }

  // fires when open or close times are selected
  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;

    // find and reduce the close time dropdown's options based on selected open time
    if (dropdown.id.includes('open-time')) {
      const row = e?.target?.closest('tr'); // the current row
      const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
      const openValue = dropdown.getSelection()?.value;
      const closeDropdown = this.getControllerByElement(dropdowns[1]); // the closeTime dropdown
      closeDropdown.items.forEach((item) => {
        if (this.strTimeForSort(openValue) >= this.strTimeForSort(item.value)) {
          // Hiding selected option, deselect it
          if (item.selected) closeDropdown.deselectItemById(item.id);

          item.hide();
        } else item.show();
      });
    }

    this.checkSaveButtonState();
  }

  // converts a string time "10:00 AM" to a number for sorting
  strTimeForSort(time) {
    const timeParts = time.split(' ');
    const timeStr = timeParts[0];
    const ampm = timeParts[1]?.toLowerCase();
    const hourMin = timeStr?.split(':');
    let hour = parseInt(hourMin[0], 10);
    if (hour === 12) hour = 0;
    if (ampm === 'pm') hour += 12;
    const min = parseInt(hourMin[1], 10);
    return hour + (min === 30 ? 0.5 : 0);
  }

  // adds a new row to the table using the template
  addRow() {
    // remove the empty row if it is present
    const emptyRow = this.targets.find('emptyRow');
    if (emptyRow) { emptyRow?.remove(); }

    // get the row template
    const newRow = document.importNode(this.rowTemplateTarget.content, true);

    // add the row to the table
    const tbody = this.tbodyTarget;
    tbody.append(newRow);

    // enable/disable save button
    this.checkSaveButtonState();
  }

  // deletes a row from the table
  deleteRow(e) {
    // remove the row from the DOM
    const row = e?.target?.closest('tr');
    row.remove();

    // remove this row from initialFormData so that the isDirty check works properly
    const index = e.target.dataset.index;
    if (index) {
      const newData = this.initialFormData.slice(0);
      newData.splice(index, 1);
      this.initialFormData = newData;

      const tbody = this.tbodyTarget;
      const rows = tbody.querySelectorAll('tr');
      // if there are no more rows, show the empty row
      if (rows.length === 0) {
        const emptyRow = document.importNode(this.emptyRowTemplateTarget.content, true);
        tbody.append(emptyRow);
      } else {
        // otherwise update the indexes on the existing rows
        rows.forEach((r, i) => {
          const deleteLink = r.querySelector('.delete-link');
          // only update indexes on rows for existing holiday hours
          // since new ones aren't in the initialFormData
          if (deleteLink.dataset.index) {
            deleteLink.dataset.index = i;
          }
        });
      }
    }

    // enable/disable save button
    this.checkSaveButtonState();
  }

  // handle leaving the page and triggering the unsaved changes modal
  leavingPage(e) {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    const controller = this.getControllerByElement(modal);

    // check if there are unsaved changes
    if (this.isDirty) {
      e.preventDefault();
      controller.setUrl(e.detail.url);
      controller.handleModal();
    }
  }

  // set isDirty false on save so we don't get the
  // unsaved changes modal when the save action redirects
  saveForm() {
    this.isDirty = false;
  }

  // sets class of datepicker to valid/invalid, fired on change
  datePicked(e) {
    // add or remove the invalid class depending on value
    if (e?.target?.value) {
      e.target.classList.remove('invalid');
    } else {
      e.target.classList?.add('invalid');
    }

    // now check save button state
    this.checkSaveButtonState();
  }
}
