import FormController from './components/form_controller';

export default class extends FormController {
  initialFormData = {};

  connect() {
    // give all elements a moment to populate before checking initial values
    setTimeout(() => {
      this.initialFormData = this.getFormValues();
    }, 100);
  }

  dropdownUpdate() {
    this.isFormChanged();
  }

  getFormValues() {
    if (!this.hasFormTarget) return [];

    const elements = [];

    const inputs = this.formTarget?.querySelectorAll('input', 'select');
    inputs.forEach((input) => {
      elements.push(input.value);
    });

    return elements;
  }

  isFormChanged() {
    const currentValues = this.getFormValues();
    const isDirty = JSON.stringify(currentValues) !== JSON.stringify(this.initialFormData);
    return isDirty;
  }

  formSaveComplete() {
    this.initialFormData = this.getFormValues();
  }

  handleModal() {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    modal.setAttribute('data-endpoint', this.getUrl());
    modal.click();
  }

  leavingPage(e) {
    if (this.isFormChanged()) {
      e.preventDefault();
      this.setUrl(e.detail.url);
      this.handleModal();
    }
  }

  setUrl(url) {
    this.url = url;
  }

  getUrl() {
    return this.url;
  }
}
