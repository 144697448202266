import Form from './form_controller';

export default class ToggleDropdownController extends Form {
  disabledClass = 'disabled';
  dropdownToggleSelector = '.dropdown-toggle';
  dropdownInputSelector = '[type=hidden]';

  static targets = ['dropdown', 'toggle', 'toggleInput'];

  connect() {
    // ensure the state of dropdownTarget matches toggleTarget
    this.toggle();
  }

  // enable/disable dropdownTarget when toggleTarget is clicked
  toggle() {
    if (this.hasDropdownTarget) {
      const disabled = !this.toggleInputTarget.checked;
      const dropdownToggle = this.dropdownTarget.querySelector(this.dropdownToggleSelector);
      const dropdownInput = this.dropdownTarget.querySelector(this.dropdownInputSelector);

      // enable/disable entire dropdown
      dropdownToggle.disabled = disabled;

      // if the dropdown is not disabled, the hidden input within should be required
      dropdownInput.required = !disabled;

      if (disabled) {
        this.dropdownTarget.classList.add(this.disabledClass);

        const dropdownController = this.getControllerByElement(this.dropdownTarget);
        if (dropdownController?.errorValue) dropdownController.errorValue = false;
      } else {
        this.dropdownTarget.classList.remove(this.disabledClass);
      }
    }
  }
}
