import FileUploadController from '../../components/file_upload_controller';

export default class ParameterOptionController extends FileUploadController {
  currStep = 0;
  currTarget = 0;

  static targets = [
    'submitButton',
  ];

  static values = {
    imageSrc: String,
  };

  connect() {
    if (this.hasPreviewImageTarget) {
      this.previewImageTarget.src = this.imageSrcValue || '';
    }
  }

  handleFileInputChange() {
    const shouldShowNextStep = super.canUploadFile();

    if (shouldShowNextStep) {
      // hide placeholder image
      if (this.hasPlaceholderImageTarget) {
        this.placeholderImageTarget.classList.add(this.hiddenClass);
      }

      // show preview image and add source
      if (this.hasPreviewImageTarget) {
        this.previewImageTarget.classList.remove(this.hiddenClass);
        this.previewImageTarget.src = URL.createObjectURL(this.file);
      }

      // enable submit button
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false;
      }
    }
  }
}
