import { Controller } from '@hotwired/stimulus';

/**
 * Controller class for Global functionality on init
 */

export default class extends Controller {
  connect() {
    // Accessibility helpers
    let keydownOnce = false;

    const handleKeydown = (e) => {
      if (!keydownOnce) {
        // Accessibility fix for tables
        const tableHolders = document.querySelectorAll('.tabulator-tableholder');
        tableHolders.forEach((tableHolder) => {
          tableHolder.setAttribute('tabindex', '-1');
        });
        keydownOnce = true;
      }

      if (e.key === 'Tab') {
        document.body.classList.add('using-keyboard');
      }
    };

    const handleMousedown = () => {
      document.body.classList.remove('using-keyboard');
    };

    document.body.addEventListener('keydown', handleKeydown);
    document.body.addEventListener('mousedown', handleMousedown);

    // Close alert helpers - Tabs for query string
    // Must be handled here to work with additional page filters
    document.addEventListener('DOMContentLoaded', () => {
      const urlParams = new URLSearchParams(window.location.search);
      const hidealert = urlParams.get('hidealert');

      if (hidealert === 'true') {
        const links = document.getElementsByClassName('tab-link');

        for (let i = 0; i < links.length; i += 1) {
          const url = new URL(links[i].href);
          url.searchParams.set('hidealert', 'true');
          links[i].href = url.toString();
        }
      }
    });
  }

  // Consider handling this in Session storage in the future if filters become too complicated
  handleCloseAlert(e) {
    e.preventDefault();

    const url = new URL(window.location.href);
    url.searchParams.set('hidealert', 'true');
    window.history.replaceState(null, null, url.toString());

    // hide alert
    e.target.closest('.catalog-publish-alert').classList.add('hidden');

    const tabs = document.getElementsByClassName('tab-link');

    for (let i = 0; i < tabs.length; i += 1) {
      const tabUrl = new URL(tabs[i].href);
      tabUrl.searchParams.set('hidealert', 'true');
      tabs[i].href = tabUrl.toString();
    }
  }
}
