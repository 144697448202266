/* eslint-disable vars-on-top */
import Form from './components/form_controller';

export default class extends Form {
  static targets = [
    'form',
    'rowField',
    'rowAction',
  ];

  initialValue = {};
  isNew = false;

  connect() {
    if (this.hasRowActionTarget) {
      this.isNew = this.rowActionTarget.closest('.tabulator-row').dataset.isNew === 'true';
    }
  }

  addRow() {
    // Check for an "empty" table, and hide the default row
    const actionCells = document.querySelectorAll('div[tabulator-field="actions"]');

    actionCells.forEach((div) => {
      if (div.children.length === 0 && !div.closest('.tabulator-row').classList.contains('editing')) {
        div.closest('.tabulator-row').classList.add('hidden', 'default');
      }
    });
  }

  editCategory() {
    // Add editing highlight
    this.rowActionTarget.closest('.tabulator-row').classList.add('editing');

    // Show input
    // eslint-disable-next-line max-len
    this.rowActionTarget.closest('.tabulator-row').getElementsByClassName('custom-category-field')[0].classList.remove('hidden');

    // Hide plain text
    // eslint-disable-next-line max-len
    this.rowActionTarget.closest('.tabulator-row').getElementsByClassName('custom-category-name')[0].classList.add('hidden');

    // Hide Edit Row
    this.rowActionTarget.getElementsByClassName('edit-row')[0].classList.add('hidden');

    // Show Save Row
    this.rowActionTarget.getElementsByClassName('save-row')[0].classList.remove('hidden');
  }

  cancelEdit() {
    // Remove row if new
    if (this.isNew) {
      // Display default row if table is "empty"
      const tableRows = document.querySelectorAll('.tabulator-row');

      if (tableRows.length === 2 && tableRows[1].classList.contains('default')) {
        // eslint-disable-next-line max-len
        this.rowActionTarget.closest('.tabulator-table').getElementsByClassName('default')[0].classList.remove('hidden');
      }

      // Remove the row
      this.rowActionTarget.closest('.tabulator-row').remove();

      return;
    }

    // Remove editing highlight
    this.rowActionTarget.closest('.tabulator-row').classList.remove('editing');

    // Hide input
    // eslint-disable-next-line max-len
    this.rowActionTarget.closest('.tabulator-row').getElementsByClassName('custom-category-field')[0].classList.add('hidden');

    // Show plain text
    // eslint-disable-next-line max-len
    this.rowActionTarget.closest('.tabulator-row').getElementsByClassName('custom-category-name')[0].classList.remove('hidden');

    // Show Edit Row
    this.rowActionTarget.getElementsByClassName('edit-row')[0].classList.remove('hidden');

    // Hide Save Row
    this.rowActionTarget.getElementsByClassName('save-row')[0].classList.add('hidden');
  }

  saveRow() {
    // Submit
    this.rowActionTarget.closest('.tabulator-row').getElementsByClassName('category-form')[0].requestSubmit();
  }
}
