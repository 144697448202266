import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // static targets = ['nav'];

  // connect() {

  //   /**
  //    *  UPDATE:: This is now handled by setting the paginated turbo-frame to have
  //    *  data-turbo-action = "advance" which should push the requested turbo url into the
  //    *  browsers history
  //    *  Solution found here: https://github.com/hotwired/turbo/issues/792#issuecomment-1680906530
  //   */

  //   // Disabled due to browser back functionality with Turbo
  //   // Various Github issues regarding the topic. Pushing for time sake
  //   // https://github.com/hotwired/turbo/issues/518
  //   // https://github.com/hotwired/turbo/issues/792
  //   // https://discuss.hotwired.dev/t/turbo-drive-stream-updates-clicking-browser-back-button-does-not-change-the-page-only-changes-url/3151

  //   // Fires each time pagination reconnects to the page
  //   // const currentPage = this.navTarget.dataset.currentPage;
  //   // const url = new URL(window.location);
  //   // url.searchParams.set('page', currentPage || 1);
  //   // window.history.pushState(null, '', url.toString());
  //   // Turbo.navigator.history.push(url.toString());
  //   // console.log(Turbo.navigator.history);
  // }
}
