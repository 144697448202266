/* eslint-disable array-callback-return */
import Form from './components/form_controller';

export default class extends Form {
  dropdownIds = ['per_page'];

  static targets = ['form', 'welcomeMessageForm', 'submitButton'];


  updateWelcomeMessage() {
    // Submits the welcome message form by clicking the hidden submit button
    this.submitButtonTarget.click();
  }
}
