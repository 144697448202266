import FormController from './components/form_controller';

export default class StorefrontGroupsController extends FormController {
  dropdownIds = ['per_page'];

  static targets = [
    'form',
  ];

  // TODO: Consolidate this with storefront_locations_controller
  // Call on checkbox toggle
  updateHeader() {
    // Get count of groups checked
    const groupCount = document.getElementById('amount-checked');

    // Get all group checkboxes
    const allGroups =
      document.querySelectorAll('.groups-table .tabulator-row .input-checkbox input[type=checkbox]');

    // Get all checked groups
    const groupsChecked = Array.from(allGroups).filter((checkbox) => {
      return checkbox.checked;
    });

    // Updated Checked Count
    groupCount.textContent = groupsChecked.length;

    // Update buttons
    document.querySelectorAll('.button-horz-group .button-secondary').forEach((button) => {
      button.disabled = !groupsChecked.length;
    });
  }

  checkAll(e) {
    const isChecked = e.target.checked;

    document.querySelectorAll(
      '.groups-table .tabulator-row .input-checkbox input[type=checkbox]').forEach((toggle) => {
      toggle.checked = isChecked;
    });

    this.updateHeader();
  }

  // Pagination dropdown
  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;

    if (dropdown.id === 'per_page') {
      const perPageValue = dropdown.inputTarget.value;
      this.formTarget.querySelector('input[name="per_page"]').value = perPageValue;
    }
  }
}
