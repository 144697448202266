import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class Tooltip extends Controller {
  static targets = ['trigger', 'content'];
  manualTrigger = 'manual';
  defaultTimeout = 8000;
  defaultTooltipTrigger = 'mouseenter focus';

  initialize() {
    this.trigger = this.getTrigger();
    this.content = this.getContent();
    this.placement = this.getPlacement();
    this.offset = this.getOffset();

    this.initTooltip();

    if (this.hasContentTarget) {
      this.contentTarget.style.display = 'none';
    }
  }

  showExistingContent() {
    this.show();
  }

  show(content = null, timeout = this.defaultTimeout) {
    if (content) {
      this.setContent(content);
    }

    this.tooltip.show();
    this.setTimeout(timeout);
  }

  hide() {
    this.tooltip.hide();
  }

  setContent(content) {
    this.tooltip.setContent(content);
  }

  setTimeout(timeout) {
    clearTimeout(this.timeout);
    if (timeout !== 0) {
      this.timeout = setTimeout(() => {
        this.tooltip.hide();
      }, timeout);
    }
  }

  initTooltip() {
    this.tooltip = tippy(this.trigger, {
      allowHTML: true,
      animation: 'shift-away',
      // for editing css
      // delay: [0, 90000],
      content: this.content,
      offset: this.offset,
      placement: this.placement,
      trigger: this.getTriggerMethod(),
      maxWidth: 'none',
    });
  }

  getTriggerMethod() {
    if (this.trigger.dataset.tooltipTrigger) {
      return this.trigger.dataset.tooltipTrigger;
    }

    if (!this.content) {
      return this.manualTrigger;
    }

    return this.defaultTooltipTrigger;
  }

  getContent() {
    if (this.hasContentTarget) {
      return this.contentTarget.innerHTML;
    }

    if (this.trigger.dataset.content) {
      return this.trigger.dataset.content;
    }

    return '';
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    }

    return this.element;
  }

  getOffset() {
    // Tooltip offset reference: https://atomiks.github.io/tippyjs/v6/all-props/#offset
    // Skidding/Distance explination: https://popper.js.org/docs/v2/modifiers/offset/
    let dist = 0;
    let skid = 0;

    // Check for offsets. Obey content offset over trigger if both exist
    if (this.hasTriggerTarget) {
      if (this.triggerTarget.dataset.tooltipSkid) {
        skid = parseInt(this.triggerTarget.dataset.tooltipSkid, 10) || skid;
      }
      if (this.triggerTarget.dataset.tooltipDistance) {
        dist = parseInt(this.triggerTarget.dataset.tooltipDistance, 10) || dist;
      }
    }
    if (this.hasContentTarget) {
      if (this.contentTarget.dataset.tooltipSkid) {
        skid = parseInt(this.contentTarget.dataset.tooltipSkid, 10) || skid;
      }
      if (this.contentTarget.dataset.tooltipDistance) {
        dist = parseInt(this.contentTarget.dataset.tooltipDistance, 10) || dist;
      }
    }

    return [skid, dist];
  }

  getPlacement() {
    // Tooltip placement reference: https://atomiks.github.io/tippyjs/v6/all-props/#placement
    if (this.hasContentTarget && this.contentTarget.dataset.placement) {
      return this.contentTarget.dataset.placement;
    }

    if (this.hasTriggerTarget && this.triggerTarget.dataset.placement) {
      return this.triggerTarget.dataset.placement;
    }

    return 'top';
  }
}
