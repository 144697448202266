import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';
// https://splidejs.com/documents/

export default class Carousel extends Controller {
  static targets = [
    'carousel',
    'carouselThumbs',
  ];

  static values = {
    options: Object,
    thumbsOptions: Object,
  };

  initialize() {
    this.initCarousel();
  }

  initCarousel() {
    // init carousel
    if (this.hasCarouselTarget) {
      this.carousel = new Splide(`#${this.carouselTarget.id}`, {
        ...this.optionsValue,
      });
    }

    // init carousel thumbnails
    if (this.hasCarouselThumbsTarget) {
      this.carouselThumbs = new Splide(`#${this.carouselThumbsTarget.id}`, {
        ...this.thumbsOptionsValue,
      });
    }

    // use thumbnails as navigation or mount single carousel
    if (this.carousel && this.carouselThumbs) {
      this.carousel.sync(this.carouselThumbs);
      this.carousel.mount();
      this.carouselThumbs.mount();
    } else if (this.carousel) {
      this.carousel.mount();
    }
  }

  disconnect() {
    // destruction!
    const destroyMode = 'completely';
    this.carousel?.destroy(destroyMode);
    this.carouselThumbs?.destroy(destroyMode);
  }
}
