// TODO: Disabled options do not properly work with nested options. Currently not needed so pushing it
// If this is ever needed we need to update updateChildElements and updateParentElements
// We can probably just check if any disabled elements exist in the list and if so handle
// the selection updates based on how many and their selected state

export default class DropdownItem {
  /**
   * @property CSS classes for different states
   */
  #classes = {
    hidden: 'hidden',
  };

  /**
   * Item in a dropdown list
   * @param {HTMLElement} element HTML element of the item
   * @param {DropdownItem} [parent] The parent node
   * @param {DropdownItem[]} [children = []] List of child nodes
   */
  constructor(element, parent = null, children = []) {
    if (!element) throw new Error('DropdownItems require an HTMLElement');

    /** @type {HTMLElement} HTML element of the item */
    this.element = element;
    /** @type {DropdownItem} */
    this.parent = parent;
    /** @type {DropdownItem[]} Children of the element */
    this.children = children;

    // Pull data attributes out for easier access
    const dataset = element.dataset;
    this.id = element.id;
    this.label = dataset.dropdownLabel;
    this.value = dataset.dropdownValue;
    this.key = dataset.dropdownKey;
    this.selected = dataset.dropdownSelected === 'true';
    this.disabled = dataset.dropdownDisabled === 'true';
    this.overrideSelectedLabel = dataset.dropdownOverrideSelected;
    this.partial = false;
    this.checkbox = element.querySelector('input');
    this.data = dataset.dropdownData ? JSON.parse(dataset.dropdownData) : null;
  }

  /**
   * Updates the items state
   * If the item has children, they are updated recursively
   *
   * When called on child items, updateParent = false to prevent infinite loops
   *
   * @param {boolean} [updateParent = true] Should we update the parent items?
   */
  select(updateParent = true) {
    if (this.selected || this.disabled) return;

    // Set flags
    this.selected = true;
    this.partial = false;

    // Update element
    this.element.setAttribute('aria-selected', true);
    this.element.dataset.dropdownSelected = true;

    // Update Checkbox
    if (this.checkbox) {
      this.checkbox.checked = true;
      this.checkbox.indeterminate = false;
    }

    // Update relatives
    if (updateParent) this.updateParentElement();
    this.updateChildElements();
  }

  /**
   * Partially selects an element
   */
  partialSelect() {
    if (this.partial) return;

    // Set flags
    this.selected = false;
    this.partial = true;

    // Update element
    this.element.setAttribute('aria-selected', false);
    this.element.dataset.dropdownSelected = false;

    // Update Checkbox
    if (this.checkbox) {
      this.checkbox.checked = false;
      this.checkbox.indeterminate = true;
    }

    // Update relatives
    if (this.parent) this.updateParentElement(true);
  }

  /**
   * Updates the items state
   * If the item has children, they are updated recursively
   *
   * When called on child items, updateParent = false to prevent infinite loops
   *
   * @param {boolean} [updateParent = true] Should we update the parent items?
   */
  deselect(updateParent = true) {
    if ((!this.selected || this.disabled) && !this.partial) return;

    // Set flags
    this.selected = false;
    this.partial = false;

    // Update element
    this.element.removeAttribute('aria-selected');
    this.element.dataset.dropdownSelected = false;

    // Update Checkbox

    if (this.checkbox) {
      this.checkbox.checked = false;
      this.checkbox.indeterminate = false;
    }

    // Update relatives
    if (updateParent) this.updateParentElement();
    this.updateChildElements();
  }

  /** Applies the hidden class to the HTMLElement */
  hide() {
    this.element.classList.add(this.#classes.hidden);
  }

  /** Removes the hidden class from the HTMLElement */
  show() {
    this.element.classList.remove(this.#classes.hidden);
  }

  /** Adds a child item to the child list */
  addChild(child) {
    this.children.push(child);
  }

  /**
   * @returns {DropdownItem[]} list of items sharing a parent
   */
  siblings() {
    if (!this.parent) return [];
    return this.parent.children.filter((sibling) => {
      return sibling.id !== this.id;
    });
  }

  /**
   * Updates all child elements based on selection
   */
  updateChildElements() {
    this.children.forEach((childNode) => {
      if (this.selected === childNode.selected) return;
      if (this.selected) childNode.select(false);
      else childNode.deselect(false);
    });
  }

  /**
   * Updates the parent element based on sibling states
   * @param {boolean} partial Partial select is the only option
   */
  updateParentElement(partial = false) {
    if (!this.parent) return;

    // Only partial selections are possible
    // Skip selection checking
    if (partial) {
      this.parent.partialSelect();
      return;
    }

    // Check the state of the sibling items to update
    // the state of the parent node
    const siblings = this.siblings();
    const numOfSiblings = siblings.length;
    const selectedSiblings = siblings.filter((sibling) => {
      return sibling.selected;
    }).length;

    if (this.selected && selectedSiblings === numOfSiblings) {
      this.parent.select();
    } else if (!this.selected && selectedSiblings === 0) {
      this.parent.deselect();
    } else this.parent.partialSelect();
  }
}
