/* eslint-disable comma-dangle */
/* eslint-disable consistent-return */
import FormController from './components/form_controller';
export default class extends FormController {
  static targets = ['form'];

  initialFormValues = [];

  connect() {
    // give the dropdowns a moment to populate
    setTimeout(() => {
      this.initialFormValues = this.getFormValues();
    }, 100);
  }

  getFormValues() {
    // if no form target, move on, such as the case with the unsaved modal itself
    if (!this.hasFormTarget) return [];

    const elements = [];

    // get all inputs
    const inputs = this?.formTarget?.querySelectorAll('input, textarea, select');
    inputs.forEach((input) => {
      elements.push(input.value);
    });

    return elements;
  }

  handleModal() {
    const modalLink = document.querySelector('.modal-unsaved-changes-link');
    modalLink.setAttribute('data-endpoint', this.getUrl());
    modalLink.click();
  }

  discardEdit() {
    this.setUrl(document.querySelector('.modal-unsaved-changes-link').getAttribute('data-endpoint'));
    window.location.href = this.getUrl();
  }

  leavingPage(e) {
    if (this.isFormChanged() || this.isTinyMCEChanged()) {
      e.preventDefault();
      this.setUrl(e.detail.url);
      this.handleModal();
    }
  }

  setUrl(url) {
    this.url = url;
  }

  getUrl() {
    return this.url;
  }

  isFormChanged() {
    const currentValues = this.getFormValues();

    // eslint-disable-next-line max-len
    const isDirty = JSON.stringify(this.initialFormValues) !== JSON.stringify(currentValues);
    return isDirty;
  }

  isTinyMCEChanged() {
    if (typeof window.tinyMCE !== 'undefined' && window.tinyMCE.activeEditor) {
      return !window.tinyMCE.activeEditor.isNotDirty;
    }
    return false;
  }

  // on successful save, set initialFormValues to the current form values
  // so isFormChanged() will return false and we can navigate away cleanly
  formSaveComplete() {
    this.initialFormValues = this.getFormValues();
  }
}
