import Form from './components/form_controller';
export default class extends Form {
  dropdownIds = ['locations', 'per_page'];
  static targets = ['form', 'statusBox', 'statusInput'];

  activeClass = 'active';

  searchFilterTimeout = 750;

  connect() {
    const initialStatus = this.getInitialStatus();
    this.setActiveStatus(initialStatus);

    if (this.hasStatusInputTarget) {
      this.statusInputTarget.value = initialStatus;
    }
  }

  setActiveStatus(initialStatus) {
    this.activeBox = this.statusBoxTargets.find((box) => {
      return box.dataset.status === initialStatus;
    });

    if (this.activeBox) {
      this.activeBox.classList.add(this.activeClass);
    }
  }

  getURLParameter(name) {
    return new URLSearchParams(window.location.search).get(name);
  }

  getInitialStatus() {
    return this.getURLParameter('filters[status]') || 'total';
  }

  toggleStatusBox(e) {
    this.activeBox = e.currentTarget;
    const status = this.activeBox.dataset.status;

    this.statusInputTarget.value = status;
    this.sendFilterRequest();

    this.statusBoxTargets.forEach((box) => {
      box.classList.remove(this.activeClass);
    });
    this.activeBox.classList.add(this.activeClass);
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const status = this.activeBox ? this.activeBox.dataset.status : 'total';
      this.statusInputTarget.value = status;
      this.sendFilterRequest();
    }, this.searchFilterTimeout);
  }
}
