import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dateFrom', 'dateTo', 'input', 'filterForm', 'hiddenInputs'];

  exportCsv() {
    if (this.hasFilterFormTarget) {
      // Adds hidden inputs to tell form to format data as csv
      const parent = this.hiddenInputsTarget.parentNode;
      this.filterFormTarget.appendChild(this.hiddenInputsTarget);
      this.filterFormTarget.submit();
      // Replaces the inputs outside the form
      parent.appendChild(this.hiddenInputsTarget);
    }
  }

  printReport() {
    const iframe = document.createElement('iframe');
    let printUrl = document.getElementById('reports-table').src;
    if (!printUrl) {
      printUrl = window.location.href;
    }
    iframe.id = 'printingIframe';
    iframe.title = 'Printing Frame';
    iframe.ariaHidden = true;
    iframe.setAttribute('src', `${printUrl}&print_report=1#iframe`);
    iframe.onload = () => {
      const removeFrame = () => {
        iframe.remove();
      };
      iframe.contentWindow.onbeforeunload = removeFrame;
      iframe.contentWindow.onafterprint = removeFrame;
      iframe.contentWindow.print();
    };
    document.body.appendChild(iframe);
  }

  checkDate() {
    const reportFrom = new Date(this.dateFromTarget.value);
    const reportTo = new Date(this.dateToTarget.value);

    if (reportFrom > new Date(this.dateFromTarget.max)) {
      this.dateFromTarget.value = this.dateFromTarget.max;
    }

    if (reportFrom < new Date(this.dateFromTarget.min)) {
      this.dateFromTarget.value = this.dateFromTarget.min;
    }

    if (reportTo > new Date(this.dateToTarget.max)) {
      this.dateToTarget.value = this.dateToTarget.max;
    }

    if (reportTo < new Date(this.dateToTarget.min)) {
      this.dateToTarget.value = this.dateToTarget.min;
    }

    if (reportFrom > reportTo) {
      this.dateToTarget.value = this.dateFromTarget.value;
    }
    // this.updateMinDate();
  }

  updateMinDate() {
    this.dateToTarget.min = this.dateFromTarget.value;
  }
}
