/* eslint-disable max-len */
import FileUploadController from './components/file_upload_controller';

export default class BannerController extends FileUploadController {
  currTarget = 0;

  connect() {
    this.handleGroupsSelection();
  }

  handleGroupsSelection() {
    const query = new URLSearchParams(window.location.search);
    const locations = query.get('filter[locations]');

    const modalHiddenInputEl = document.querySelector('#modal-container #storefront_selected_location_id');
    const welcomeMessageHiddenInputEl = document.querySelector('#banner-message-section #storefront_selected_location_id');
    const locationsStr = `{"locations" => "${locations}"}`;

    if (modalHiddenInputEl) {
      modalHiddenInputEl.value = locationsStr;
    }

    if (welcomeMessageHiddenInputEl) {
      welcomeMessageHiddenInputEl.value = locationsStr;
    }
  }

  handleFileInputChange() {
    const shouldShowNextStep = super.canUploadFile();
    if (shouldShowNextStep) {
      // hide the current step
      this.stepTargets[0].classList.add(this.hiddenClass);

      // set preview image
      this.previewImageTarget.src = URL.createObjectURL(this.file);

      // show the second step
      this.stepTargets[1].classList.remove(this.hiddenClass);
    }
  }
}
