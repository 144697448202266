import { Controller } from '@hotwired/stimulus';

export default class FileUploadController extends Controller {
  file = '';

  allowedFileTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
  ];

  errorMessages = {
    fileType: 'Please check your file type and try again.',
  };

  // css
  hiddenClass = 'hidden';
  modalErrorClass = 'modal-show-error';

  static targets = [
    'errorMessage',
    'file',
    'placeholderImage',
    'previewImage',
    'step',
  ];

  isValidFileType(file) {
    return this.allowedFileTypes.includes(file.type);
  }

  showErrorMessage(target, msg) {
    this.errorMessageTargets[target].innerText = msg;
    this.errorMessageTargets[target].classList.add(this.modalErrorClass);
  }

  hideErrorMessage(target) {
    this.errorMessageTargets[target].classList.remove(this.modalErrorClass);
  }

  handleFileInputOpen(e) {
    // allow user to select the same file twice
    this.fileTargets[this.currTarget].value = null;

    // trigger a click on the file input if user presses Enter on the upload button
    if (e instanceof KeyboardEvent && e.key === 'Enter') {
      this.fileTargets[this.currTarget].click();
    }

    this.hideErrorMessage(this.currTarget);
  }

  canUploadFile() {
    const files = this.fileTargets[this.currTarget].files || [];

    if (files.length > 0) {
      this.file = files[0];
      if (this.isValidFileType(this.file)) {
        return true;
      }

      this.showErrorMessage(this.currTarget, this.errorMessages.fileType);
      return false;
    }
    return false;
  }
}
