/* eslint-disable func-names */
import { Controller } from '@hotwired/stimulus';

import {
  FormatModule,
  FrozenColumnsModule,
  GroupRowsModule,
  SortModule,
  Tabulator,
} from 'tabulator-tables';

Tabulator.registerModule([
  FormatModule,
  FrozenColumnsModule,
  GroupRowsModule,
  SortModule,
]);

export default class extends Controller {
  maxTableHeight = '605px';
  scrollEl;
  shadowEl;
  shadowElHidden = true;

  classes = {
    frozenColEl: 'tabulator-frozen-left',
    hidden: 'invisible',
    paginationHidden: 'pagination-hidden',
    scrollingEl: 'tabulator-tableholder',
    shadowEl: 'col-shadow',
    tabulatorTemp: 'tabulator-temp',
  };

  static targets = ['table'];

  static values = {
    columns: Array,
    config: String,
    groupBy: String,
    layout: String,
    rows: Array,
  };

  defaultConfig = {
    columns: this.columnsValue.map((col) => {
      const updatedCol = col;
      const sortField = col?.sorterParams?.field;
      if (col.formatter === 'html' && sortField) {
        // custom sorter for html formatted columns that use the 'field' sorterParam for comparison
        // * see _locations_list_table.html.erb's column and row definitions for an example
        updatedCol.sorter = function(a, b, aRow, bRow) {
          const aValue = aRow.getData()[sortField];
          const bValue = bRow.getData()[sortField];
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        };
      }
      return updatedCol;
    }),
    data: this.rowsValue,
    groupBy: this.groupByValue || null,
    groupToggleElement: 'header',
    layout: this.layoutValue || 'fitColumns',
    renderHorizontal: 'virtual',
    selectable: false,
  };

  configs = {
    recentChangelogConfig: {
      ...this.defaultConfig,
      // eslint-disable-next-line no-unused-vars
      groupHeader: function(value, count, data, group) {
        // value: the value all members of this group share
        // count: the number of rows in this group
        // data: an array of all the row data objects in this group
        // group: the group component for the group

        // eslint-disable-next-line max-len
        return `<div class="tabulator-group-header-left">${value}<span class="pill color-bg-pale-beige">${count}</span></div>`;
      },
    },
    pastChangelogConfig: {
      ...this.defaultConfig,
      groupHeader: function(value, count) {
        return `${value}<span class="pill color-bg-n1-300">${count}</span>`;
      },
    },
    orderQueueConfig: {
      ...this.defaultConfig,
      rowFormatter: function(row) {
        const data = row.getData();
        const rowElem = row.getElement();
        if (data.expired_raw) {
          rowElem.classList.add('expiring-order');
        }
      },
      // height: '100%', // if no maxHeight, set this height
      maxHeight: this.maxTableHeight,
    },
    locationsConfig: {
      ...this.defaultConfig,
      // height: '100%', // if no maxHeight, set this height
      maxHeight: this.maxTableHeight,
    },
    locationsBasicConfig: {
      ...this.defaultConfig,
      // height: '100%', // if no maxHeight, set this height
      maxHeight: this.maxTableHeight,
      renderVertical: 'basic', // needed for the select all checkboxes functionality to work
    },
    defaultConfig: {
      ...this.defaultConfig,
    },
  };

  connect() {
    const config = this.configs[this.configValue];
    const table = this.tableTarget;

    this.tabulatorTable = new Tabulator(table, config);

    this.tabulatorTable.on('tableBuilt', () => {
      // attempt to prevent a flash of the table (fade in)
      document.querySelector(`.${this.classes.tabulatorTemp}`)?.classList.remove(this.classes.tabulatorTemp);

      // prevent a flash of the pagination above the table (fade in)
      // NOTE: a 'hidden' local var needs to be passed to the partial for this to work
      document.querySelector(`.${this.classes.paginationHidden}`)?.classList.remove(this.classes.paginationHidden);

      // add drop shadow below frozen column if there is one
      const frozenColumn = document.querySelector(`.${this.classes.frozenColEl}`);
      if (frozenColumn) {
        const frozenColumnWidth = frozenColumn.getBoundingClientRect().width;
        const shadowDiv = document.createElement('div');
        this.shadowEl = table.appendChild(shadowDiv);
        shadowDiv.classList.add(this.classes.shadowEl, this.classes.hidden);
        shadowDiv.style.width = `${frozenColumnWidth}px`;

        this.scrollEl = document.querySelector(`.${this.classes.scrollingEl}`);
        this.scrollEl.addEventListener('scroll', () => {
          if (this.scrollEl.scrollLeft > 0 && this.shadowElHidden) {
            this.shadowElHidden = false;
            this.shadowEl.classList.remove(this.classes.hidden);
          } else if (this.scrollEl.scrollLeft <= 0 && !this.shadowElHidden) {
            this.shadowElHidden = true;
            this.shadowEl.classList.add(this.classes.hidden);
          }
        });
      }
    });
  }
}
