import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  CLASS_NAME_NO_TRANSITION = 'no-transition';
  CLASS_NAME_OPEN = 'menu-open';
  NAV_ITEM = '.nav-item';
  NAV_LINK_ACTIVE = '.nav-link.active';
  TREE_MENU = '.nav-treeview';
  static targets = ['sidebar'];

  // expand tree if it has an active link
  connect() {
    const currTarget = this.sidebarTarget.querySelector(this.NAV_LINK_ACTIVE);
    if (currTarget) {
      const tree = currTarget.closest(this.TREE_MENU);
      if (tree) {
        const navItem = tree.closest(this.NAV_ITEM);

        // prevent animation
        navItem.classList.add(this.CLASS_NAME_NO_TRANSITION);

        this.expand(tree, navItem);

        // restore animation
        setTimeout(() => {
          navItem.classList.remove(this.CLASS_NAME_NO_TRANSITION);
        }, 500);
      }
    }
  }

  // toggle tree collapse/expand
  toggle(event) {
    event.preventDefault();
    const currTarget = event.currentTarget;
    const parentTarget = currTarget.parentElement;
    const tree = parentTarget.querySelector(this.TREE_MENU);
    const treeIsOpen = parentTarget.classList.contains(this.CLASS_NAME_OPEN);

    if (treeIsOpen) {
      this.collapse(tree, parentTarget);
    } else {
      this.expand(tree, parentTarget);
    }
  }

  // remove class and height
  collapse(tree, parent) {
    // eslint-disable-next-line no-param-reassign
    tree.style.maxHeight = null;
    parent.classList.remove(this.CLASS_NAME_OPEN);
  }

  // add class and height
  expand(tree, parent) {
    // eslint-disable-next-line no-param-reassign
    tree.style.maxHeight = `${tree.scrollHeight}px`;
    parent.classList.add(this.CLASS_NAME_OPEN);
  }
}
