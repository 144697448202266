import FormController from './components/form_controller';

export default class LocationsController extends FormController {
  dropdownIds = ['catalogs', 'groups', 'per_page'];

  static targets = [
    'form',
    'holidayTable',
    'toggle',
  ];

  // toggles for online ordering and photocake images
  toggle() {
    this.formTarget.requestSubmit();
  }

  collapseTable() {
    this.holidayTableTarget.classList.toggle('open');
  }
}
