import { post, get } from '@rails/request.js';
import FormController from '../../components/form_controller';

export default class extends FormController {
  initialFormData = {};

  static targets = [
    'additionalOptionsEmpty',
  ];

  static values = {
    optionSetWarningModalUrl: String,
  };

  static outlets = [
    'option-set-table'
  ];

  errorClass = 'error';
  hiddenClass = 'hidden';
  alertErrorClass = 'alert-red';
  alertGrayClass = 'alert-gray';

  initialOptionSets = '';
  optionSetDropdownId = 'option-sets-dropdown';
  optionSetDropdownController = null;

  inputTargetConnected(element) {
    const input = element.querySelector('input');
    this.initialFormData[input.name] = input.value;
  }

  dropdownReady(e) {
    const dropdown = e.detail.dropdown;
    this.initialFormData[dropdown.name] = dropdown.currentValue;
    // Save controller reference for later
    if (dropdown.id === this.optionSetDropdownId) this.optionSetDropdownController = dropdown;
  }

  leavingPage(e) {
    let editingOptions = false;
    let editingInputs = false;
    let editingDropdowns = false;

    // Checks if Base/Additional option tables are being edited
    if (this.hasOptionSetTableOutlet) {
      editingOptions = this.optionSetTableOutlets.some((controller) => {
        return controller.isEditing();
      });
    }

    // Getting inputs manually here since targets are referencing wrapper elements
    editingInputs = this.inputTargets.some((element) => {
      const input = element.querySelector('input');
      return this.initialFormData[input.name] !== input.value;
    });

    // Getting inputs manually here since targets are referencing wrapper elements
    editingDropdowns = this.dropdownTargets.some((element) => {
      const input = element.querySelector('input');
      return this.initialFormData[input.name] !== input.value;
    });

    if (editingDropdowns || editingInputs || editingOptions) {
      const modalLink = document.querySelector('.modal-unsaved-changes-link');
      e.preventDefault();
      modalLink.setAttribute('data-endpoint', e.detail.url);
      modalLink.click();
    }
  }

  formSaveComplete() {
    // Getting inputs manually here since targets are referencing wrapper elements
    this.inputTargets.forEach((element) => {
      const input = element.querySelector('input');
      this.initialFormData[input.name] = input.value;
    });

    // Getting inputs manually here since targets are referencing wrapper elements
    this.dropdownTargets.forEach((element) => {
      // Ignore this dropdown as form can be saved while it is still being edited
      if (element.id === 'option-sets-dropdown') return;
      const input = element.querySelector('input');
      this.initialFormData[input.name] = input.value;
    });
  }

  confirmOptionSets(e) { this.updateOptions(e, true); }

  updateOptions(e, confirmed = false) {
    if (!this.optionSetDropdownController) return;
    const optionValues = this.optionSetDropdownController.currentValue;
    const inputName = this.optionSetDropdownController.name;
    const url = this.optionSetDropdownController.controllerTarget.dataset.url;

    if (!this.optionSetDropdownController.validate()) return;

    // Button was pressed with same selection
    if (this.initialFormData[inputName] === optionValues) return;

    const removedOptions = this.initialOptionSets.split(',').some((id) => { return !optionValues.includes(id); });

    if (removedOptions && !confirmed) {
      get(this.optionSetWarningModalUrlValue, {
        query: {
          // eslint-disable-next-line camelcase
          option_sets: optionValues,
        },
        responseKind: 'turbo-stream'
      });
      return;
    }
    const buttonController = this.getControllerByElement(e.currentTarget);
    buttonController.toggleLoading(true);
    post(url, {
      body: {
        // eslint-disable-next-line camelcase
        option_sets: optionValues,
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    }).then((res) => {
      if (res.ok) {
        this.initialFormData[inputName] = optionValues;
        if (this.hasAdditionalOptionsEmptyTarget) {
          if (optionValues.length) {
            this.additionalOptionsEmptyTarget.classList.add(this.hiddenClass);
          } else {
            this.additionalOptionsEmptyTarget.classList.remove(this.hiddenClass);
          }
        }
      }
      buttonController.toggleLoading(false);
    });
  }
}
