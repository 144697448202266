// import { get } from '@rails/request.js';

import Dropdown from './components/dropdown_controller';
import Form from './components/form_controller';

export default class extends Form {
  static targets = ['submit', 'featuredDesignPreview'];

  dropdowns = {
    category: null,
    // design: null,
    position: null,
  };

  dropdownReady(e) {
    this.dropdowns[e.detail.dropdown.id] = e.detail.dropdown;
    if (Object.values(this.dropdowns).every((d) => { return d !== null; })) {
      this.validate();
    }

    // if (e.detail.dropdown.id === 'design' && this.dropdowns.design.getSelection()) {
    //   this.updatePreviewImage(this.dropdowns.design.getSelection().data?.img);
    // }
  }

  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.id === 'category') this.categorySelected(dropdown);
    // else if (dropdown.id === 'design') this.designSelected(dropdown);
    this.validate();
  }

  /**
   * Update designs dropdown based on selected category
   * @param {Dropdown} dropdown Category dropdown controller
   */
  categorySelected(dropdown) {
    const selection = dropdown.getSelection();

    // No selection. Skip everything else
    if (!selection) return;

    // Throw error when dependent element cant be found
    if (!dropdown.dependentElement) throw Error('Dependent element missing for dropdown');

    // Initializing with value, enable designs and prevent refresh
    // if (dropdown.initializing) {
    //   dropdown.updateDependent(Dropdown.events.disable);
    //   return;
    // }

    dropdown.updateDependent(Dropdown.events.enable);

    // get(`${dropdown.streamValue}?selected=${selection.value}`, {
    //   contentType: 'application/json',
    //   responseKind: 'turbo-stream',
    // }).then(() => {
    //   dropdown.updateDependent(Dropdown.events.reload);
    // });
  }

  /**
   * Update designs dropdown based on selected category
   * @param {Dropdown} dropdown Category dropdown controller
   */
  designSelected(dropdown) {
    const selection = dropdown.getSelection();

    // Throw error when dependent element cant be found
    if (!dropdown.dependentElement) throw Error('Dependent element missing for dropdown');
    if (!this.hasFeaturedDesignPreviewTarget) throw Error('Design Preview element missing');

    // No selection, set preview to blank state
    if (!selection) {
      this.featuredDesignPreviewTarget.setAttribute('src', '');
      this.featuredDesignPreviewTarget.classList.add('blank');
      dropdown.updateDependent(Dropdown.events.disableEvent, {});
      return;
    }

    // Selection made, update preview
    dropdown.updateDependent(Dropdown.events.enable);
    // Update preview
    if (selection.data?.img) {
      this.updatePreviewImage(selection.data.img);
    }
  }

  /**
   * Update the src of the design preview
   * @param {string} imgSrc Image source
   */
  updatePreviewImage(imgSrc) {
    if (imgSrc) {
      this.featuredDesignPreviewTarget.parentElement.classList.remove('blank');
      this.featuredDesignPreviewTarget.setAttribute('src', imgSrc);
    }
  }

  validate() {
    let valid = true;

    if (!this.dropdowns.category?.selection.length) {
      valid = false;
    }

    // if (!this.dropdowns.category?.selection.length) {
    //   valid = false;
    //   this.dropdowns.design?.disable();
    // } else this.dropdowns.design?.enable();

    // if (!this.dropdowns.design?.selection.length) {
    //   valid = false;
    //   this.dropdowns.position?.disable();
    // } else this.dropdowns.position?.enable();

    if (!valid) {
      this.dropdowns.position?.disable();
      this.submitTarget.setAttribute('disabled', true);
    } else {
      this.dropdowns.position?.enable();
      this.submitTarget.removeAttribute('disabled');
    }
  }
}
