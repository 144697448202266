import Form from './components/form_controller';

export default class MfaCreateController extends Form {
  static targets = [
    'channelDropdown',
    'emailInputWrapper',
    'input',
    'phoneInputWrapper',
    'submitButton',
  ];

  validate(e) {
    let valid = false;
    const channelController = this.getControllerByElement(this.channelDropdownTarget);
    const phoneController = this.getControllerByElement(this.inputTargets[0]);
    const emailController = this.getControllerByElement(this.inputTargets[1]);

    const channelValue = channelController.currentValue;
    channelController.validate();

    if (channelValue === 'totp' ||
        (channelValue === 'sms' && phoneController.validate()) ||
        (channelValue === 'email' && emailController.validate())) {
      valid = true;
    }

    if (!valid) {
      e.preventDefault();
    }
  }

  dropdownUpdate(e) {
    const value = e.target.value;

    const channelController = this.getControllerByElement(this.channelDropdownTarget);
    channelController.validate();

    if (value === 'sms') { // SMS
      this.phoneInputWrapperTarget.classList.remove('hidden');
      this.emailInputWrapperTarget.classList.add('hidden');
    } else if (value === 'email') { // Email
      this.phoneInputWrapperTarget.classList.add('hidden');
      this.emailInputWrapperTarget.classList.remove('hidden');
    } else { // TOTP
      this.phoneInputWrapperTarget.classList.add('hidden');
      this.emailInputWrapperTarget.classList.add('hidden');
    }
  }
}
