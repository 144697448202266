/* eslint-disable no-useless-return */
import { get } from '@rails/request.js';

import Form from './../components/form_controller';

export default class CatalogSettingsController extends Form {
  currencyTargetPrefix = 'currency-';
  disabledClass = 'disabled';
  hiddenClass = 'hidden';
  initialLocations = [];

  static targets = [
    'currency',
    'toggle',
    'baseOptionTable',
    'baseOptionTableAddBtn',
    'optionSetTable',
    'removedLocationModalButton',
    'locationDropdown',
  ];

  connect() {
    setTimeout(() => {
      this.initialLocations = this.getInitialValues();
    }, 100);
  }


  openOptionSet(e) {
    // Ignore toggle when event is bubbled from button
    if (e.target instanceof HTMLAnchorElement) return;
    const header = e.currentTarget;
    const body = header.nextElementSibling;
    body.classList.toggle(this.hiddenClass);
    header.classList.toggle('open');
    const expanded = !(header.getAttribute('aria-expanded') === 'true');
    header.setAttribute('aria-expanded', expanded);
  }


  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.initializing) return;
    if (dropdown.id === 'item-type-dropdown') {
      this.updateItemType(e);
    }
  }

  updateItemType(e) {
    const url = `${this.formTarget.dataset.updateItemTypeUrl}?item_type[id]=${e.target.value}`;
    get(url, { contentType: 'application/json', responseKind: 'turbo-stream' });
  }

  getInitialValues() {
    const locationDropdownInput = document.querySelector('#locations-dropdown-input');
    const initialValuesString = locationDropdownInput.value;

    if (initialValuesString) {
      return initialValuesString.split(',');
    }
    return [];

  }

  dropdownBlur(e) {
    // eslint-disable-next-line max-len
    const dropdown = e.detail.dropdown;
    const isLocationRemoved = this.initialLocations.some((value) => {
      return value === e.detail.removed[0]?.value;
    });

    if (dropdown.initializing || !e.detail.wasChanged) return;

    if (dropdown.id === 'locations') {
      if (this.hasRemovedLocationModalButtonTarget) {
        this.updateRemoveLocationButtonUrl(dropdown.inputTarget.value);

        if (isLocationRemoved) {
          this.handleLocationModal();
        }
      }
    }
  }

  updateRemoveLocationButtonUrl(locations) {
    const url = new URL(this.removedLocationModalButtonTarget.href);
    url.searchParams.set('locations', locations);
    this.removedLocationModalButtonTarget.href = url.href;
  }

  handleLocationModal() {
    const modal = this.formTarget.querySelector('.modal-removed-location-link');
    modal.click();
  }

  // eslint-disable-next-line no-unused-vars, no-empty-function
  toggleBaseOption(e) { }

  // eslint-disable-next-line no-unused-vars, no-empty-function
  toggleOption(e) { }
}
