import Form from './components/form_controller';

export default class extends Form {
  static targets = [
    'buttonPrint',
    'formAction',
    'orderDetailsWrapper',
    'conditionalRadio',
    'conditionalHiddenInput',
  ];

  toggleConditional() {
    this.conditionalHiddenInputTarget.value = this.conditionalRadioTarget.checked ? 'true' : 'false';
  }

  acceptNewOrder(evt) {
    // event.preventDefault();
    const form = evt.currentTarget.closest('form');
    // eslint-disable-next-line no-unused-vars
    const conditionalInput = form.querySelector('input[id="order-conditional"]').checked;

    // eslint-disable-next-line no-unused-vars
    const hiddenInput = document.getElementById('conditional-hidden');
    const formData = new FormData(evt.currentTarget.closest('form'));

    formData.set('order[conditional]', this.hiddenInput.value);

    // Target when errors are resolved
    // Turbo.visit(event.target.href, {
    //   method: "put",
    //   body: formData,
    // });

    // Working form
    // Submit the form using Turbo Frames
    // Turbo.visit(form.action, {
    //   frame: form.getAttribute('data-turbo-frame'),
    //   action: 'replace',
    //   body: new FormData(form).append('conditional', conditional),
    // });
  }
}
