import Form from './../components/form_controller';

export default class CatalogProductDetails extends Form {

  static targets = ['carouselContent', 'submitButton'];

  connect() {
    // Toggle open on load
    this.carouselContentTargets.forEach((item) => {
      this.handleToggle(item);
    });
  }

  toggleCarousel(e) {
    const clickedEl = e.target;
    this.carouselContentTargets.forEach((item) => {
      if (clickedEl === item) {
        this.handleToggle(item);
      }
    });
  }

  handleToggle(item) {
    const content = item.children[1];
    const icon = item.children[0].children[1];

    content.classList.toggle('active');
    icon.classList.toggle('open');

    if (content.classList.contains('active')) {
      content.style.height = `${content.scrollHeight}px`;
    } else {
      content.style.height = '';
    }
  }

  toggleStatus() {
    this.submitButtonTarget.click();
  }
}
