import { get } from '@rails/request.js';
import FormController from './../components/form_controller';

import Dropdown from './../components/dropdown_controller';

export default class extends FormController {
  initialFormData = {};

  // eslint-disable-next-line no-unused-vars
  dropdownBlur(e) {
    const dropdown = e.detail.dropdown;

    if (dropdown.initializing || !e.detail.wasChanged) return;

    if (dropdown.id === 'product-type') {
      const selection = dropdown.getSelection() || '';
      get(`${dropdown.streamValue}?product_type=${selection.value}`, {
        contentType: 'application/json',
        responseKind: 'turbo-stream',
      }).then(() => {
        dropdown.updateDependent(Dropdown.events.reload, { enable: selection !== '' });
      });
    }
  }
}
