// eslint-disable-next-line no-unused-vars
import { Turbo } from '@hotwired/turbo-rails';
import Form from '../components/form_controller';

export default class extends Form {
  static targets = ['templatePill', 'pillList', 'pill', 'submitButton'];

  filterTimeoutId = null;
  typeFilterTimeout = 1250;
  searchFilterTimeout = 750;

  connect() {
    // Reinit the search field from params
    const params = new URLSearchParams(window.location.search);
    const searchInput = this.formTarget.querySelector('input[id=search]');
    if (searchInput && params.get('filters[search]')) {
      searchInput.value = params.get('filters[search]');
    }
  }

  resetScroll() {
    // Scroll user to top after turbo-frame reloads
    this.formTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  sendFilterRequest() {
    // Submits form while allowing Turbo to intercept
    this.submitButtonTarget.click();
  }

  searchDesigns() {
    // Delay search to allow user to finish typing
    if (this.filterTimeoutId) window.clearTimeout(this.filterTimeoutId);
    this.filterTimeoutId = window.setTimeout(() => {
      this.sendFilterRequest();
    }, this.searchFilterTimeout);
  }

  // Update pill list after filter change
  updatePills(currentValue, previousValue) {
    const added = currentValue.length > previousValue.length;
    if (added) {
      // Get the difference of the two values
      const diff = currentValue.filter((item1) => {
        return !previousValue.some((item2) => {
          return item1.value === item2.value;
        });
      });

      // Loop over new items and generate pill
      diff.forEach((item) => {
        const newEle = this.templatePillTarget.cloneNode(true);
        newEle.classList.remove('hidden');
        newEle.dataset.designFilterTarget = 'pill';
        newEle.id = item.value;
        newEle.dataset.value = item.value;
        newEle.dataset.label = item.label;
        newEle.querySelector('.filter-pill-text').textContent = item.label;
        this.pillListTarget.appendChild(newEle);
      });
    } else {
      // Get the difference of the two values and extract only the value
      const diff = previousValue.filter((item1) => {
        return !currentValue.some((item2) => {
          return item1.value === item2.value;
        });
      }).map((diffObj) => { return diffObj.value; });

      this.pillTargets.forEach((pillEle) => {
        // Check if the pill is set to be removed
        if (diff.includes(pillEle.dataset.value)) {
          pillEle.remove();
        }
      });
    }
  }

  // ! Currently broken
  // eslint-disable-next-line no-unused-vars
  removePill(e) {
    // Get the pill from the event
    // const clickedPill = e.target.closest('.filter-pill');
    // const dropdownController = this.getDropdownControllerById('design-type-filter');
    // if (!dropdownController) return;
    // dropdownController.removeByValue(clickedPill.dataset.value);
  }

  dropdownReady(dropdown) {
    // The type filter needs to display filter pills
    if (dropdown.id === 'design-type-filter') {
      // this.updatePills(dropdown); // ! Currently broken
    }
  }

  dropdownBlur(e) {
    const dropdown = e.detail.dropdown;
    // Send the filter request
    if (dropdown.id === 'design-type-filter') {
      // The type filter needs to display filter pills
      // this.updatePills(dropdown); // ! Currently broken
      this.sendFilterRequest();
    } else if (dropdown.id === 'design-status-filter') {
      this.sendFilterRequest();
    }
  }
}
