import { put } from '@rails/request.js';

import Form from '../components/form_controller';

export default class extends Form {
  static targets = [
    'header',
    'body',
    'row',
    'table',
    'wrapper',
    'shadow',
    'controls',
    'editWrapper',
    'viewOptionsButton',
  ];

  static outlets = [
    'toggle',
    'text-input'
  ];

  // Control vars
  shadowHidden = true;
  isBaseTable = false;
  baseTableActiveToggleName = 'active';

  // Classes
  hiddenClass = 'hidden';
  editingClass = 'editing';
  invalidClass = 'invalid';
  openClass = 'open';
  newClass = 'new';

  // Data mapping of inputs, rows, and columns
  optionSets = {};

  /** Accessor methods */
  isEditing() {
    return Object.values(this.optionSets).some((set) => { return set.editing; });
  }

  /** Setup Methods */
  connect() {
    // Track if connected table is to be used as a base table
    if (this.hasTableTarget && this.tableTarget.dataset.isBaseTable === 'true') this.isBaseTable = true;
  }

  // Option set header row
  // Creates the base data for the option set
  headerTargetConnected(ele) {
    const isNew = ele.dataset.isNew === 'true';
    this.optionSets[ele.dataset.optionSetId] = {
      header: ele,
      body: null,
      controls: null,
      editWrapper: null,
      editing: isNew,
      isNew: isNew,
      rows: {},
    };
  }

  // Element for save and cancel buttons
  controlsTargetConnected(ele) {
    this.optionSets[ele.dataset.optionSetId].controls = ele;
  }

  // Element for edit button
  editWrapperTargetConnected(ele) {
    this.optionSets[ele.dataset.optionSetId].editWrapper = ele;
  }

  // Option set body element
  bodyTargetConnected(ele) {
    this.optionSets[ele.dataset.optionSetId].body = ele;
  }

  // Base option table view options button
  viewOptionsButtonTargetConnected(ele) {
    this.optionSets[ele.dataset.optionSetId].rows[ele.dataset.optionId].viewOptionsButton = ele;
  }

  // Option set table price input cells
  inputCellTargetConnected(ele) {
    this.optionSets[ele.dataset.optionSetId].rows[ele.dataset.optionId].prices[ele.dataset.priceId] = {
      baseId: ele.dataset.baseOption,
      price: null,
      toggle: null
    };
  }

  // Option row element
  // Sets up the data structure for each individual option and its applicable inputs
  rowTargetConnected(ele) {
    const rowData = {
      element: ele,
      viewOptionsButton: null, // View options button for base option table
      prices: {},
      metas: [], // Metadata fields that apply to the entire row
      isNew: ele.dataset.isNew || false,
    };
    this.optionSets[ele.dataset.optionSetId].rows[ele.dataset.optionId] = rowData;

  }

  // Connects the text input controller to its proper option set, option, and price ids
  textInputOutletConnected(controller, element) {
    const optionSetId = element.dataset.optionSetId;
    const optionId = element.dataset.optionId;
    const priceId = element.dataset.priceId;
    const inputType = element.dataset.inputType;
    const optionSetRow = this.optionSets[optionSetId].rows[optionId];
    const inputData = {
      element,
      controller,
    };
    if (inputType === 'price') optionSetRow.prices[priceId].price = inputData;
    else if (inputType === 'meta') optionSetRow.metas[controller.name] = inputData;
  }

  // Removes stale references if items are removed from the DOM
  textInputOutletDisconnected(controller, element) {
    // If an input gets disconnected, we want to remove it from optionSets to prevent stale data
    const optionSetId = element.dataset.optionSetId;
    const optionId = element.dataset.optionId;
    const priceId = element.dataset.priceId;
    const inputType = element.dataset.inputType;
    const optionSetRow = this.optionSets[optionSetId].rows[optionId];
    if (inputType === 'price') {
      optionSetRow.prices[priceId].price = null;
    } else if (inputType === 'meta') {
      optionSetRow.metas[controller.name] = null;
      // optionSetRow.metas = optionSetRow.metas.filter((input) => { return input.element !== element; });
    }
  }

  // Connects the toggle input controller to its proper option set, option, and price ids
  toggleOutletConnected(controller, element) {
    const optionSetId = element.dataset.optionSetId;
    const optionId = element.dataset.optionId;
    const priceId = element.dataset.priceId;
    const inputType = element.dataset.inputType;
    const optionSetRow = this.optionSets[optionSetId].rows[optionId];
    const inputData = {
      element,
      controller,
    };
    // Allow meta toggles, used for base options
    if (inputType === 'meta') optionSetRow.metas[controller.name] = inputData;
    else if (priceId) optionSetRow.prices[priceId].toggle = inputData;
  }

  // Removes stale references if items are removed from the DOM
  toggleOutletDisconnected(controller, element) {
    // If an input gets disconnected, we want to remove it from optionSets to prevent stale data
    const optionSetId = element.dataset.optionSetId;
    const optionId = element.dataset.optionId;
    const priceId = element.dataset.priceId;
    const inputType = element.dataset.inputType;
    const optionSetRow = this.optionSets[optionSetId].rows[optionId];
    if (inputType === 'meta') {
      optionSetRow.metas[controller.name] = null;
      // optionSetRow.metas = optionSetRow.metas.filter((input) => { return input.element !== element; });
    } else {
      optionSetRow.prices[priceId].toggle = null;
    }
  }

  /** Control Methods */
  detectScroll() {
    if (this.wrapperTarget.scrollLeft > 0 && this.shadowHidden) {
      this.shadowHidden = false;
      this.shadowTarget.classList.remove(this.hiddenClass);
    } else if (this.wrapperTarget.scrollLeft <= 0 && !this.shadowHidden) {
      this.shadowHidden = true;
      this.shadowTarget.classList.add(this.hiddenClass);
    }
  }

  scrollToOptions(e) {
    const baseId = e.currentTarget.dataset.optionId;
    const optionSetTableWrapper = document.querySelector('div.option-set-table-wrapper');
    const optionCol = optionSetTableWrapper.querySelector(`th[data-base-option="${baseId}"]`);

    // Have to offset for width of sticky column
    const firstCol = optionSetTableWrapper.querySelector('table th:first-of-type');
    const leftOffset = firstCol.getBoundingClientRect().width;

    // Scroll to table, wait, then scroll to column
    // Not ideal but works for now
    optionSetTableWrapper.scrollIntoView({ block: 'center', behavior: 'smooth' });
    window.setTimeout(() => {
      // Using scrollTo to prevent the viewport from scrolling
      optionSetTableWrapper.scrollTo({ left: optionCol.offsetLeft - leftOffset, behavior: 'smooth' });
    }, 750);
  }

  open(e) {
    const optionSetId = e.currentTarget.dataset.optionSetId;
    const setData = this.optionSets[optionSetId];
    const header = setData.header;
    setData.body.classList.toggle(this.hiddenClass);
    const expanded = !(header.getAttribute('aria-expanded') === 'true');
    header.setAttribute('aria-expanded', expanded);
    header.classList.toggle('open');
  }

  edit(e) {
    const optionSetId = e.currentTarget.dataset.optionSetId;
    const setData = this.optionSets[optionSetId];
    const header = setData.header;

    // Enable all of the inputs
    Object.values(setData.rows).forEach((row) => {
      Object.values(row.metas).forEach((meta) => {
        meta.controller.enable();
      });
      Object.values(row.prices).forEach((price) => {
        // Only enable the price field if toggle is enabled
        if (price.toggle.controller.currentValue) price.price.controller.enable();
        price.toggle.controller.enable();
      });
    });

    // Set classes
    if (header.getAttribute('aria-expanded') === 'true') e.stopPropagation();
    setData.body.classList.add('editing');
    setData.editWrapper.classList.add(this.hiddenClass);
    setData.controls.classList.remove(this.hiddenClass);
    setData.editing = true;
  }

  cancelEdit(e) {
    const optionSetId = e.currentTarget.dataset.optionSetId;
    const setData = this.optionSets[optionSetId];

    // Keep track of new rows that were canceled to delete after looping
    const rowsToDelete = [];

    // Disable inputs and revert values
    Object.entries(setData.rows).forEach(([key, row]) => {
      if (row.isNew) {
        // Canceling edits on an new "unsaved" row means to delete it.... I think
        // Only applies to the Catalog Settings version of this table
        rowsToDelete.push(key);
        return;
      }
      // * Note: inputController.resetValue(disable = false, clearError = false)
      Object.values(row.metas).forEach((meta) => {
        meta.controller.resetValue(true, true);
      });
      Object.values(row.prices).forEach((price) => {
        // Must set value through autonumeric
        price.price.controller.resetValue(true, true);
        // Toggles don't get validated so errors don't need to be cleared
        price.toggle.controller.resetValue(true);
      });

      // Make sure invalid state doesn't persist
      row.element.classList.remove(this.invalidClass);
    });

    // Set classes
    setData.body.classList.remove('editing');
    setData.editWrapper.classList.remove(this.hiddenClass);
    setData.controls.classList.add(this.hiddenClass);
    setData.editing = false;
  }

  save(e) {
    const optionSetId = e.currentTarget.dataset.optionSetId;
    const setData = this.optionSets[optionSetId];
    let isFormValid = true;

    const body = {
      // eslint-disable-next-line camelcase
      option_data: {}
    };

    Object.entries(setData.rows).forEach(([key, row]) => {
      // Validate, save to body, update initial
      let isRowValid = true;
      body.option_data[key] = {};
      const rowBodyData = body.option_data[key];
      Object.entries(row.metas).forEach(([name, meta]) => {
        const value = meta.controller.currentValue;
        const modified = value !== meta.controller.initialValue;
        // If not modified, skip validation
        if (modified) {
          if (meta.controller.validate()) {
            // Metadata is stored for the entire option, so it lives at top level
            rowBodyData[name] = value;
          } else isRowValid = false;
        }
      });
      Object.entries(row.prices).forEach(([priceId, price]) => {
        const tModified = price.toggle.controller.currentValue !== price.toggle.controller.initialValue;
        const pModified = price.price.controller.currentValue !== price.price.controller.initialValue;
        // Validate and add to payload if field is modified
        if (tModified || pModified) {
          // If toggle is off, skip price validation
          if (!price.toggle.controller.currentValue || price.price.controller.validate()) {
            if (!(priceId in rowBodyData)) rowBodyData[priceId] = {};
            // Only add modified data to payload
            if (pModified) rowBodyData[priceId].price = price.price.controller.currentValue || null;
            if (tModified) rowBodyData[priceId].active = price.toggle.controller.currentValue;
          } else isRowValid = false;
        }
      });

      if (!isRowValid) {
        row.element.classList.add(this.invalidClass);
        isFormValid = false;
      } else row.element.classList.remove(this.invalidClass);

      // Remove the option data if not modified
      if (Object.keys(rowBodyData).length === 0) delete body.option_data[key];
    });

    if (!isFormValid) {
      return;
    }

    const url = e.currentTarget.dataset.url;
    put(url, {
      body,
      responseKind: 'turbo-stream'
    }).then((resp) => {
      if (resp.ok) {
        setData.header.classList.remove(this.newClass);
        setData.body.classList.remove(this.editingClass);
        setData.editWrapper.classList.remove(this.hiddenClass);
        setData.controls.classList.add(this.hiddenClass);
        setData.editing = false;
        setData.isNew = false;
        let optionSetTableController = null;
        if (this.isBaseTable) {
          // This is a base options table. Grab reference to the additional options table
          // eslint-disable-next-line max-len
          optionSetTableController = this.getControllerByElement(document.querySelector('#option-set-table-controller'));
        }
        // Save successful. Update new initial values
        Object.values(setData.rows).forEach((row) => {
          Object.values(row.metas).forEach((meta) => {
            meta.controller.initialValue = meta.controller.currentValue;
            // Special treatment for base table to reveal/hide columns
            if (meta.controller.name === this.baseTableActiveToggleName) {
              // Update additional option table
              if (optionSetTableController) {
                optionSetTableController.updateOptions(
                  meta.element.dataset.optionId,
                  meta.controller.currentValue
                );
              }
              // Toggle view options button
              if (row.viewOptionsButton) {
                // Only enable buttons when optionSetTable exists
                // OptionSetTableController being null here means that no option sets are added
                // meaning we do not want the view options buttons to be enabled
                if (meta.controller.currentValue && optionSetTableController) {
                  row.viewOptionsButton.removeAttribute('disabled');
                } else row.viewOptionsButton.setAttribute('disabled', true);
              }
            }
            meta.controller.disable();
          });
          Object.values(row.prices).forEach((price) => {
            price.price.controller.initialValue = price.price.controller.currentValue;
            price.toggle.controller.initialValue = price.toggle.controller.currentValue;
            price.price.controller.disable();
            price.toggle.controller.disable();
          });
        });
      } else {
        // Something went wrong in the save controller. Log and render error
        // TODO: Log NewRelic error here
        // console.log(resp.statusCode);
      }
    }).catch((err) => {
      // Something went wrong when dispatching the request. Log error
      // TODO: Log NewRelic error here
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }

  togglePriceState(e) {
    const element = e.currentTarget;
    const optionSetId = element.dataset.optionSetId;
    const optionId = element.dataset.optionId;
    const priceId = element.dataset.priceId;
    const priceData = this.optionSets[optionSetId].rows[optionId].prices[priceId];

    // Check for all required elements
    if (!priceData || !priceData?.toggle || !priceData?.price) return;
    // if (priceData.toggle.controller.isDisabled) return;
    const priceController = priceData.price.controller;
    const isActive = priceData.toggle.controller.currentValue;

    // Update required value and enable/disable price field
    priceController.setRequired(isActive);
    if (isActive) priceController.enable();
    else {
      priceController.disable();
      // Make sure to clear old errors
      priceController.clearError();
    }
  }

  // TODO: Look into refactoring this for bulk saving
  // Fires when a base option is toggled.
  // Shows or hides the associated column in the option set table
  updateOptions(optionId, active) {
    const selector = `th[data-base-option="${optionId}"]`;
    const cellIndex = this.headerTarget.querySelector(selector).cellIndex;
    const isActive = active;
    if (isActive) {
      this.headerTargets.forEach((t) => {
        t.querySelector('tr').cells[cellIndex]?.classList.remove(this.hiddenClass);
      });
      this.rowTargets.forEach((t) => {
        t.cells[cellIndex]?.classList.remove(this.hiddenClass);
      });
    } else {
      this.headerTargets.forEach((t) => {
        t.querySelector('tr').cells[cellIndex]?.classList.add(this.hiddenClass);
      });
      this.rowTargets.forEach((t) => { t.cells[cellIndex]?.classList.add(this.hiddenClass); });
    }

    // Update required states of price inputs
    Object.values(this.optionSets).forEach((set) => {
      Object.values(set.rows).forEach((row) => {
        const priceData = Object.values(row.prices).find((price) => {
          return price.baseId === optionId;
        });
        priceData.price.controller.setRequired(isActive);
        // If the price was hidden while editing, reset its value and clear errors
        if (!isActive) {
          priceData.price.controller.resetValue(true, true);
          priceData.toggle.controller.resetValue(true);
        }
      });
    });
  }


  // eslint-disable-next-line no-empty-function
  addOptionSet() {}

  // eslint-disable-next-line no-empty-function
  addOption() {}
}
