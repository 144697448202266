import FormController from './components/form_controller';

export default class extends FormController {
  formCheckTimeout = 250;
  formCheckTimeoutId = null;
  isDirty = false;
  locationsSelected = false;

  static targets = [
    'saveButton',
    'rowTemplate',
    'tbody',
    'dropdown',
    'locationsDropdown',
    'groupsDropdown',
  ];

  // enables/disables a row's open/close time dropdowns
  // based on the state of the open/close toggle switch
  toggleOpen(e) {
    const row = e?.target?.closest('tr'); // the current row
    const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
    const toggleWrapper = row?.querySelector('.input-toggle'); // the wrapper for the toggle
    const toggle = toggleWrapper?.querySelector('input[type=checkbox]'); // the toggle input itself
    const checked = toggle?.checked; // whether it's checked or not

    // loop through the dropdowns and enable or disable them
    dropdowns?.forEach((d) => {
      const controller = this.getControllerByElement(d);
      if (checked) {
        controller.enable();
      } else {
        controller.disable();
      }
    });
  }

  // enable/disable fields based on if locations have been selected
  enableDisableFields(row, isOpen) {
    // grab all the controllers
    const datePickerController = this.getControllerByElement(row.querySelector('.datepicker'));
    const openToggleController = this.getControllerByElement(row.querySelector('.input-toggle-wrapper'));
    const descriptionController = this.getControllerByElement(row.querySelector('[data-input-name="holidays[][note]"]'));// eslint-disable-line max-len
    const newRowDescriptionController = this.getControllerByElement(row.querySelector('[data-input-name="new[][note]"]'));// eslint-disable-line max-len
    const dropdowns = row.querySelectorAll('[data-controller=basic-dropdown]');
    const openDropdownController = this.getControllerByElement(dropdowns[0]);
    const closedDropdownController = this.getControllerByElement(dropdowns[1]);

    if (this.locationsSelected) {
      datePickerController.enable();
      openToggleController.enable();
      descriptionController?.enable();
      newRowDescriptionController?.enable();

      // only enable the open/close dropdowns if this row is set to open
      if (isOpen) {
        openDropdownController.enable();
        closedDropdownController.enable();
      }
    } else {
      datePickerController.disable();
      openToggleController.disable();
      descriptionController?.disable();
      newRowDescriptionController?.disable();
      openDropdownController.disable();
      closedDropdownController.disable();
    }
  }

  // debounce for startCheckSaveButtonState() below
  checkSaveButtonState() {
    if (this.formCheckTimeoutId) clearTimeout(this.formCheckTimeoutId);
    this.formCheckTimeoutId = setTimeout(() => {
      this.startCheckSaveButtonState();
    }, this.formCheckTimeout);
  }

  // sets the save button enabled or disabled based on form state
  startCheckSaveButtonState() {
    // check if stores have been selected
    const selectedLocations = this.locationsDropdownTarget.querySelector('input').value;
    const selectedGroups = this.groupsDropdownTarget.querySelector('input').value;
    this.locationsSelected = !!(selectedLocations || selectedGroups);

    // check the form to see if it's unfinished
    const tbody = this.tbodyTarget;
    const rows = tbody.querySelectorAll('tr');
    const rowChecks = [];
    const rowDirty = [];
    rows.forEach((row) => {
      // grab all the fields
      const datePicker = row.querySelector('.datepicker .date');
      const openToggle = row.querySelector('input[type=checkbox');
      const openDropdown = row.querySelector('input[id$=open-time-dropdown-input]');
      const closeDropdown = row.querySelector('input[id$=close-time-dropdown-input]');
      const descriptionField = row.querySelector('.text');

      // now check field values to determine isDirty
      const hasDate = !!datePicker.value;
      const isOpen = !!openToggle.checked;
      const hasOpen = !!openDropdown.value;
      const hasClose = !!closeDropdown.value;
      const hasHours = isOpen ? (hasOpen && hasClose) : true;
      const hasDesc = !!descriptionField.value;
      rowChecks.push(hasDate && hasHours && hasDesc);
      rowDirty.push(hasDate || isOpen || hasDesc);

      // enable/disable fields based on if locations have been selected
      this.enableDisableFields(row, isOpen);
    });
    const formComplete = !rowChecks.some((r) => { return r === false; });
    this.isDirty = rowDirty.some((r) => { return r; });

    // enable or disable the save button
    this.saveButtonTarget.disabled = !this.locationsSelected || !formComplete;
  }

  // fires when stores are selected or deselected
  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    // find and reduce the close time dropdown's options based on selected open time
    if (dropdown.id.includes('open-time')) {
      const row = e?.target?.closest('tr'); // the current row
      const dropdowns = row.querySelectorAll('.dropdown'); // dropdowns in this row
      const openValue = dropdown.getSelection()?.value;
      const closeParent = dropdowns[1]; // the closeTime dropdown
      const closeList = closeParent?.querySelector('.dropdown-list')?.childNodes[1]?.childNodes;
      closeList.forEach((r) => {
        const rowValue = r.dataset?.dropdownValue;
        if (rowValue) {
          if (this.strTimeForSort(openValue) >= this.strTimeForSort(rowValue)) {
            r.classList.add('hidden');
          } else {
            r.classList?.remove('hidden');
          }
        }
      });
    }

    this.checkSaveButtonState();
  }

  // converts a string time "10:00 AM" to a number for sorting
  strTimeForSort(time) {
    const timeParts = time.split(' ');
    const timeStr = timeParts[0];
    const ampm = timeParts[1]?.toLowerCase();
    const hourMin = timeStr?.split(':');
    let hour = parseInt(hourMin[0], 10);
    if (hour === 12) hour = 0;
    if (ampm === 'pm') hour += 12;
    const min = parseInt(hourMin[1], 10);
    return hour + (min === 30 ? 0.5 : 0);
  }

  // adds a new row to the table using the template
  addRow() {
    // get the row template
    const newRow = document.importNode(this.rowTemplateTarget.content, true);

    // add the row to the table
    const tbody = this.tbodyTarget;
    tbody.append(newRow);

    // hide delete button on last remaining row
    this.hideFirstDeleteButton(tbody);

    // enable/disable save button
    this.checkSaveButtonState();
  }

  // deletes a row from the table
  deleteRow(e) {
    e.preventDefault();
    e.stopPropagation();

    // remove the row from the DOM
    const row = e?.target?.closest('tr');
    row.remove();

    // hide delete button on last remaining row
    const tbody = this.tbodyTarget;
    this.hideFirstDeleteButton(tbody);

    // enable/disable save button
    this.checkSaveButtonState();
  }

  // handles showing/hiding of delete buttons on applicable rows
  hideFirstDeleteButton(tbody) {
    // check how many rows there are in the table
    const rows = tbody?.querySelectorAll('tr');
    const rowCount = rows?.length;

    // if there are multiple rows, enable all delete buttons.
    // otherwise hide last remaining row's delete button
    if (rowCount > 1) {
      rows.forEach((row) => {
        const deleteButton = row.querySelector('.delete-col');
        deleteButton.classList.remove('no-delete');
      });
    } else {
      const row1 = rows[0]?.querySelector('.delete-col');
      row1.classList.add('no-delete');
    }
  }

  // handle leaving the page and triggering the unsaved changes modal
  leavingPage(e) {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    const controller = this.getControllerByElement(modal);

    // check if there are unsaved changes
    if (this.isDirty) {
      e.preventDefault();
      controller.setUrl(e.detail.url);
      controller.handleModal();
    }
  }

  // set isDirty false on save so we don't get the
  // unsaved changes modal when the save action redirects
  saveForm() {
    this.isDirty = false;
  }

  // sets class of datepicker to valid/invalid, fired on change
  datePicked(e) {
    // add or remove the invalid class depending on value
    if (e?.target?.value) {
      e.target.classList.remove('invalid');
    } else {
      e.target.classList?.add('invalid');
    }

    // now check save button state
    this.checkSaveButtonState();
  }
}
