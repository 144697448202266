import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  loading = false;

  connect() {
    this.element.ariaBusy = this.loading;
  }

  // used mainly for testing the component
  toggleLoading(forceValue = null) {
    this.loading = forceValue || !this.loading;
    this.element.ariaBusy = this.loading;
  }

  // used mainly for listening to a dispatched event from another controller
  // ex: this.dispatch('loading', { detail: { loading: true } });
  setLoading(e) {
    if (e.detail.loading) {
      this.startLoading();
    } else {
      this.stopLoading();
    }
  }

  // used mainly for listeners in the erb on the button
  startLoading(e) {
    const submitter = e.detail.formSubmission.submitter;
    if (!e || this.element === submitter) {
      this.element.ariaBusy = true;
    }
  }

  // used mainly for listeners in the erb on the button
  stopLoading() {
    this.element.ariaBusy = false;
  }
}
