import { get } from '@rails/request.js';
import Form from '../../components/form_controller';


export default class CatalogParameterAssignProductController extends Form {

  static targets = ['productCard', 'hiddenForm', 'addedInput', 'form',
    'removedInput', 'removeProductsModalLink', 'modalRemoveCount'];

  dropdownIds = ['categories', 'status', 'decopac_types', 'comp_type', 'per_page'];

  changeList = {
    added: [],
    removed: []
  };

  confirmed = false;

  productCardTargetConnected(ele) {
    // Ensure the checkboxes reflect the current temp state of the product
    const productId = ele.dataset.productId;
    const toggle = ele.querySelector('input');
    if (this.changeList.added.includes(productId)) {
      toggle.checked = true;
    } else if (this.changeList.removed.includes(productId)) {
      toggle.checked = false;
    }
  }

  modalRemoveCountTargetConnected(ele) {
    // Update modal product count copy
    const removeCount = this.changeList.removed.length;
    ele.textContent = `(${removeCount}) product${removeCount > 1 ? 's':''}`;
  }

  updateProduct(e) {
    const id = e.target.value;
    const addedIdx = this.changeList.added.indexOf(id);
    const removedIdx = this.changeList.removed.indexOf(id);

    if (removedIdx > -1) this.changeList.removed.splice(removedIdx, 1);
    else if (!e.target.checked && addedIdx === -1) this.changeList.removed.push(id);

    if (addedIdx > -1) this.changeList.added.splice(addedIdx, 1);
    else if (e.target.checked && removedIdx === -1) this.changeList.added.push(id);
  }

  selectAll() {
    this.productCardTargets.forEach((cardEle) => {
      const toggle = cardEle.querySelector('input');
      if (!toggle.checked) toggle.click();
    });
  }

  removeAll() {
    this.productCardTargets.forEach((cardEle) => {
      const toggle = cardEle.querySelector('input');
      if (toggle.checked) toggle.click();
    });
  }

  submit() {
    if (!this.hasHiddenFormTarget) return;

    if (!this.confirmed && this.changeList.removed.length) {
      get(this.removeProductsModalLinkTarget.href,
        { responseKind: 'turbo-stream' }
      );
      return;
    }

    this.addedInputTarget.value = this.changeList.added.join(',');
    this.removedInputTarget.value = this.changeList.removed.join(',');

    this.hiddenFormTarget.requestSubmit();
  }

  confirmRemoveProducts() {
    this.confirmed = true;
    this.submit();
  }

  resetState() {
    this.changeList.added = [];
    this.changeList.removed = [];
  }

  formSaveComplete() {
    this.resetState();
  }

  leavingPage(e) {
    const targetUrl = new URL(e.detail.url);
    if (window.location.pathname === targetUrl.pathname) return;
    if (this.changeList.added.length || this.changeList.removed.length) {
      e.preventDefault();
      const modalLink = document.querySelector('.modal-unsaved-changes-link');
      modalLink.setAttribute('data-endpoint', targetUrl.href);
      modalLink.click();
    }
  }

  discardEdit() {
    const url = document.querySelector('.modal-unsaved-changes-link').getAttribute('data-endpoint');
    window.location.href = url;
  }

  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.id === 'per_page') {
      const perPageValue = dropdown.inputTarget.value;
      this.formTarget.querySelector('input[name="per_page"]').value = perPageValue;
    }
  }
}
