import FormController from './components/form_controller';

export default class extends FormController {
  initialState = {};
  isFormDirty = false;

  static targets = [
    'dropdown',
    'form',
    'row',
    'saveButton',
    'toggle',
    'toggle247',
  ];

  // fires when the controller is attached
  connect() {
    // give all elements a moment to populate before checking initial values
    setTimeout(() => {
      this.initialState = this.serializeForm();
    }, 100);
  }

  handleToggleChange(e) {
    const toggle = e.target;
    const row = toggle.closest('tr');
    const dropdowns = [...row.querySelectorAll('.dropdown')];
    const checked = toggle.checked;

    // enable or disable dropdowns
    dropdowns.forEach((dropdown) => {
      const controller = this.getControllerByElement(dropdown);

      if (checked) {
        controller.enable();
      } else {
        controller.disable();
      }
    });

    this.updateSubmitButtonState();
  }

  handle247ToggleChange(e) {
    const toggle247 = e.target;
    const checked = toggle247.checked;
    const elementTargets = [...this.dropdownTargets, ...this.toggleTargets];

    if (checked) {
      // disable elementTargets
      elementTargets.forEach((element) => {
        const controller = this.getControllerByElement(element);
        controller.disable();
      });
    } else {
      // enable all toggles, and dropdowns per row if toggle is checked
      this.rowTargets.forEach((row) => {
        const toggle = row.querySelector('.input-toggle-wrapper');
        const toggleController = this.getControllerByElement(toggle);
        toggleController.enable();

        if (toggleController.currentValue) {
          const dropdowns = [...row.querySelectorAll('.dropdown')];
          dropdowns.forEach((dropdown) => {
            const dropdownController = this.getControllerByElement(dropdown);
            dropdownController.enable();
          });
        }
      });
    }

    this.updateSubmitButtonState();
  }

  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;

    // update close time dropdown
    if (dropdown.id.includes('open-time')) {
      const openValue = dropdown.currentValue;
      const openTime = this.strTimeForSort(openValue);
      const row = dropdown.element.closest('tr');
      const dropdowns = [...row.querySelectorAll('.dropdown')];
      const closeDropdown = this.getControllerByElement(dropdowns[dropdowns.length - 1]);

      closeDropdown.items.forEach((item) => {
        if (openTime >= this.strTimeForSort(item.value)) {
          // hiding selected option, deselect it
          if (item.selected) {
            closeDropdown.deselectItemById(item.id);
          }

          item.hide();
        } else {
          item.show();
        }
      });
    }

    this.updateSubmitButtonState();
  }

  // converts a string time "10:00 AM" to a number for sorting
  strTimeForSort(timeStr) {
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    const isAM = period === 'AM';
    const isPM = period === 'PM';

    let number = parseInt(hours);

    if (minutes !== '00') {
      number += 0.5;
    }

    if (isAM && number === 12) {
      number = 0;
    }

    if (isPM && number < 12) {
      number += 12;
    }

    return number;
  }

  updateSubmitButtonState() {
    let hasValidRows;
    const toggle247 = this.getControllerByElement(this.toggle247Target);

    if (toggle247.currentValue) {
      hasValidRows = true;
    } else {
      hasValidRows = this.rowTargets.every((row) => {
        const toggle = row.querySelector('.input-toggle-wrapper');
        const toggleController = this.getControllerByElement(toggle);
        const toggleValue = toggleController.currentValue;
        const dropdowns = [...row.querySelectorAll('.dropdown')];

        // toggle isn't checked, or all dropdowns have values
        return !toggleValue || dropdowns.every((element) => {
          const controller = this.getControllerByElement(element);
          const value = controller.currentValue;
          return !!value !== false;
        });
      });
    }

    this.isFormDirty = this.hasFormChanged();
    this.saveButtonTarget.disabled = !(hasValidRows && this.isFormDirty);
  }

  serializeForm() {
    const data = {};

    const elementTargets = [
      ...this.dropdownTargets,
      ...this.toggleTargets,
      this.toggle247Target,
    ];

    // store relevant element data
    elementTargets.forEach((element) => {
      const controller = this.getControllerByElement(element);
      const name = controller.name;
      const value = controller.currentValue;
      data[name] = value;
    });

    return data;
  }

  hasFormChanged() {
    const currentState = this.serializeForm();
    return JSON.stringify(this.initialState) !== JSON.stringify(currentState);
  }

  // handle leaving the page and triggering the unsaved changes modal
  leavingPage(e) {
    const modal = this.formTarget.querySelector('.modal-unsaved-changes-link');
    const controller = this.getControllerByElement(modal);

    // check if there are unsaved changes
    if (this.isFormDirty) {
      e.preventDefault();
      controller.setUrl(e.detail.url);
      controller.handleModal();
    }
  }

  // set isFormDirty false on save so we don't get the
  // unsaved changes modal when the save action redirects
  saveForm() {
    this.isFormDirty = false;
  }
}
