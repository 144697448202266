import Form from '../components/form_controller';

export default class extends Form {
  dropdownIds = ['status', 'per_page'];

  static targets = [
    'submitButton',
  ];

  filterTimeoutId = null;
  searchFilterTimeout = 750;

  resetScroll() {
    // Scroll user to top after turbo-frame reloads
    this.formTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  dropdownUpdate(e) {
    const dropdown = e.detail.dropdown;
    if (dropdown.id === 'per_page') {
      const perPageValue = dropdown.inputTarget.value;
      this.formTarget.querySelector('input[name="per_page"]').value = perPageValue;
    }
  }
}
